import { Directive, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Directive({
  selector: '[browserBackAction]'
})
export class BrowserBackActionDirective {
  constructor(private location: Location, private router: Router,) { }

  @HostListener('click')
  public onClick(event: MouseEvent): void {
    let Orgin = window.location.origin;
    let History = document.referrer.slice(0,document.referrer.lastIndexOf('/'));
    if (History && History === Orgin)
      this.location.back();
    else {
      let flag = window.location.pathname.slice(1);
      let category = window.location.search.split('&') ? window.location.search.split('&')[0] : '';
      
      switch (flag) {
        case 'appointment-time-slots':
          if (category)
            this.router.navigateByUrl('/appointment-visit-reason' + category);
          else
            this.router.navigate(['appointment']);
          break;
        default:
          this.router.navigate(['appointment']);
      }
    }
  }
}
