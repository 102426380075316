import { Component, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { Router } from '@angular/router';
import { KnownVareModel, KnownVareResponse, PatientRefferal } from 'src/app/_models/registermodel';
import { RegistrationService } from 'src/app/_services/registration.service';
import { BroadCastService } from 'src/app/_shared/_services/broadcast.service';
import { LoaderService } from 'src/app/_shared/_services/loader.service';

@Component({
  selector: 'app-referral',
  templateUrl: './referral.component.html'
})
export class ReferralComponent implements OnInit {
  knownVcare!: KnownVareResponse;
  PatientId: Number = 0;
  isRegistration:boolean = false;
  booleanValue:any;
  registrationToAppointment:boolean = false;

  constructor(
    private title: Title,
    private router: Router,
    private registrationService: RegistrationService,
    private broadCastService: BroadCastService,
    private loader:LoaderService

  ) {
    title.setTitle("VCare Urgent Care | Booking Appointment");
    // let Referral = localStorage.getItem("Referral");
    this.PatientId = Number(localStorage.getItem("PatientId"));

    // if (!Referral)
    //   this.router.navigate(['referral']);
    this.knownVcare = new KnownVareResponse();
  }

  ngOnInit() {
    // //this.broadCastService.setMessage('PageLoad');
    this.booleanValue = localStorage.getItem('IsServiceRegistration');
    this.isRegistration = JSON.parse(this.booleanValue);
    this.registrationToAppointment = localStorage.getItem('registrationToAppointment') != null;
    this.loader.setLoaderStatus(true);
    this.getKnownVareList();
  }

  getKnownVareList() {
    this.registrationService.getKnownVCareList().subscribe(data => {
      if (data) {
        this.knownVcare.knownVcare = data.knownVcare;
        this.loader.setLoaderStatus(false);
      }
    });
  }
  onSelect(value: Number) {
    this.loader.setLoaderStatus(true);
    let request = new PatientRefferal();
    request.PatientId = this.PatientId;
    request.knownVcareId = value;
    this.registrationService.savePatientReferral(request).subscribe(data => {
      if (data) {
        if(!this.isRegistration && !this.registrationToAppointment)
        {
          setTimeout(()=>{ this.router.navigate(['appointment-success']);},350);
        }
        else if(this.registrationToAppointment){
          setTimeout(()=>{ this.router.navigate(['appointment']);},350);
        }
        else{
          setTimeout(()=>{ this.router.navigate(['registrationsuccess']);},350);
        }
      }
    });

    localStorage.removeItem("IsServiceRegistration");
    localStorage.removeItem("ActiveForm");
    // localStorage.removeItem("PatientId");
    localStorage.removeItem("Referral");
    // localStorage.removeItem("registrationDetails");
    this.loader.setLoaderStatus(false);
  }

}
