<a  browserBackAction class="back-btn">
  <i class="ti ti-arrow-narrow-left"></i>
</a>
<div class="max-670">
  <div class="content-header">
    <h2>How can we help you ?</h2>
    <p>with {{ CategoryName }} Services</p>
  </div>
  <div class="content-scroll">
    <div class="category-search" *ngIf="!isCovid">
      <input type="text" placeholder="Search for symptoms" autocomplete="new-password" [(ngModel)]="SearchReason" name="Search for your symptoms" />

      <button><i class="ti ti-search"></i></button>
    </div>
    <div class="appointment-category">
      <ul>
        <li *ngFor="
            let appointment of visitreasonservicesubwithslot
              | searchVisitReasonFilter : SearchReason;
            index as i
          ">
          <a *ngIf="appointment.name !== 'Others'" href="{{appointment.URL}}" (click)="SetVisitReasonId(appointment)">
            <i class="ti ti-stethoscope position-icon left-icon"></i>
            <div class="category-slot">
              <h4>
                {{ appointment.name }}<span>{{ appointment.Slot }}</span>
              </h4>
            </div>
            <!-- <p>{{appointment.Description}}, etc.,</p> -->
            <i class="ti ti-arrow-narrow-right position-icon right-icon"></i>
          </a>

          <a *ngIf="appointment.name === 'Others'" href="{{ appointment.URL }}" id="Others" class="other-reason"
            data-bs-toggle="modal" data-bs-target="#others-popup" (click)="Bind()">
            <i class="ti ti-stethoscope position-icon left-icon"></i>
            <div class="category-slot">
              <h4>
                {{ appointment.name }}<span>{{ appointment.Slot }}</span>
              </h4>
            </div>
            <!-- <p>{{appointment.Description}}</p> -->
            <i class="ti ti-arrow-narrow-right position-icon right-icon"></i>
          </a>
        </li>
      </ul>
    </div>

    <div *ngIf="isCovid" class="other-dropdown">
      <div class="col-lg-12 col-md-12 d-flex flex-column appoint-select2">
        <div class="search-results vc-of-unset">
          <ul>
            <li *ngFor="let item of masterDDList?.CovidTest">
              <input type="checkbox" name="check" (change)="changed($event,item)" />

              <span>
                <i class="ti ti-stethoscope position-icon left-icon"></i>

                {{ item.value }}

                <h6 class="available-slot">{{ item.Slot }}</h6>

                <i class="ti ti-check position-icon right-icon"></i>
              </span>
            </li>
          </ul>
        </div>
      </div>

      <label class="text-danger" *ngIf="isCovidWarning">Covid Reason is required.</label>
    </div>

    <div class="sticky-btn sticky-slot" *ngIf="isCovid">
      <div class="d-flex justify-content-between align-items-center max-670">
        <div *ngIf="isCovid" class="slot-label">
          <strong>Note :</strong> Rapid PCR Travel - Charge is <i>$150</i> per
          test
        </div>
        <button class="slot-btn" (click)="onFindSlots(null)">Find Slots <i
            class="ti ti-arrow-narrow-right"></i></button>
      </div>
    </div>
  </div>
</div>
<!-- Others Popup -->
<div class="modal fade appointment-popup" id="others-popup" tabindex="-1" aria-labelledby="exampleModalLabel"
  aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <button type="button" #closeModal class="btn-close" data-bs-dismiss="modal" aria-label="Close">
        <i class="ti ti-x"></i>
      </button>
      <h5>
        Please provide any additional symptoms that have not been mentioned in
        the list.
      </h5>
      <textarea placeholder="Other symptoms" id="OtherReason" maxlength="150"></textarea>
      <button class="slot-btn" (click)="getOtherReasons()">
        Find Slot <i class="ti ti-arrow-narrow-right"></i>
      </button>
      <Span *ngIf="otherValidation" class="required-field">Visit reason type is required</Span>
      <Span *ngIf="charValidation" class="required-field">Visit reason type should have valid characters</Span>
    </div>
  </div>
</div>
