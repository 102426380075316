import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AppointmentComponent } from "./appointment/appointment.component";
import { VisitReasonComponent } from "./visitreason/visitreason.component";
import { TimeSlotComponent } from "./timeslots/timeslots.component";
import { AppointmentSuccessComponent } from "./appointmentsuccess/appointmentsuccess.component";

// Shared Lib Imports


// Compontents Imports
// import { LoginComponent } from "./login/login.component";

const routes: Routes = [
  // {
  //   path: '',
  //   // component: AppointmentComponent
  //   redirectTo:'appointment'

  // },
  {
    path: 'appointment',
    component: AppointmentComponent
  },
  {
    path: 'appointment-visit-reason',
    component: VisitReasonComponent
  },
  {
    path: 'appointment-time-slots',
    component: TimeSlotComponent
  },
  {
    path: 'appointment-success',
    component: AppointmentSuccessComponent
  }
  // {
  //   path: 'appointment-visit-reason',
  //   redirectTo:'visit-reason'
  // } ,
  // {
  //   path: 'appointment/visit-reason/time-slots',
  //   redirectTo:'time-slots'
  // },
  // {
  //   path: 'appointment/success',
  //   redirectTo:'success'
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PatientAppointmentRouting {

}

