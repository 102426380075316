
<app-header [ngClass] = "getPageStatus() ? 'notfound-page' : ''" ></app-header>
<div class="content-section" [ngClass] = "getSuccessPageStatus() ? 'registeration-success' : ''">
        <alert></alert>
        <router-outlet></router-outlet>
</div>
<div *ngIf="getLoaderStatus()" class="preloader">
  <div class="heart-loader">
    <div class="pre-loader pre-loader_bubble"></div>
  </div>
</div>
