import { Component, OnInit } from '@angular/core';
import { Title,Meta } from "@angular/platform-browser";
import { AppointmentVisitReasonSlotModel } from '../../../_models/visitreasonmodel';
import { AppointmentService } from 'src/app/_services/appointment.service';
import { BroadCastService } from 'src/app/_shared/_services/broadcast.service';

import { CommonHelper } from 'src/app/_shared/_helpers/CommonHelper';
import { AlertService } from 'src/app/_shardviews/_alert';
import { LoaderService } from 'src/app/_shared/_services/loader.service';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html'
})
export class AppointmentComponent {

  appointmentreasonwithslot!: Array<AppointmentVisitReasonSlotModel>;
  // ErrorMessage: string = "Something went wrong, kindly contact admin.";

  constructor(
    private commonHelper: CommonHelper,
    private appointmentService: AppointmentService,
    private title: Title,
    private meta: Meta,
    private alertMe:AlertService,
    private loader:LoaderService,
    private broadCastService: BroadCastService
  ) {
    title.setTitle(`Book an Appointment Portal - VCare  South Brunswick, NJ`);
    this.meta.updateTag({name:'description',content:`Book an Appointment - VCare provides Urgent & Primary care services to Adults & Kids in Brunswick, Dayton, NJ. Book an Appointment Instantly`})
  }

  ngOnInit() {
    if (localStorage.getItem('registrationToAppointment') == null || localStorage.getItem('PatientId') == null)
      localStorage.clear();
    //this.broadCastService.setMessage('PageLoad');

    this.appointmentreasonwithslot = [];
    // this.commonHelper.AddPathBeforeInURL("appointment");
    this.loader.setLoaderStatus(true);
    this.getVisitResons();
  }

  getVisitResons() {
    this.appointmentService.getVisitCategory().subscribe((data: any[]) => {
      if (data) {
        data.forEach((key: any, val: any) => {
          key.URL = `/appointment-visit-reason?category=${encodeURIComponent(key.name)}`;
          this.appointmentreasonwithslot.push(key);
        });
        if(this.appointmentreasonwithslot?.filter(x => x.name == 'COVID Testing').toString() != ''){
          let covidStorage = this.appointmentreasonwithslot?.filter(x => x.name == 'COVID Testing') && this.appointmentreasonwithslot.filter(x => x.name == 'COVID Testing')[0].Slot;
          localStorage.setItem("covidtime", covidStorage);
          localStorage.setItem("categoryitems", JSON.stringify(data));
        }
        this.loader.setLoaderStatus(false);
      }
    });
  }
}


