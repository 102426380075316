import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  KnownVareResponse,
  PatientRefferal,
  PatientRegisterModel,
  RegisterModel,
  SaveLogModel,
} from '../_models/registermodel';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class RegistrationService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  private checkPatientExistance_api_url: string = `/appointment/Appointment/CheckPatientExistance/`;
  private registerPatient_api_url: string = `/patient/Patient/save-patient-details/`;
  private knownVCareList_api_url: string = `/registration/Registration/knownVcare/`;
  private savereferral_api_url: string = `/registration/Registration/save-patient-referral/`;
  private saveRegLog_api_url: string = `/appointment/Appointment/SaveAppointmentLog/`;

  private unsubscribe_api_url: string = `/registration/Unsubscribe/unsubscribeme`;

  //#region Registration
  checkPatientExistance(request: any) {
    return this.http.post<Array<RegisterModel>>(
      `${environment.apiUrl}${this.checkPatientExistance_api_url}`,
      request,
      this.httpOptions
    );
  }

  registerPatient(request: PatientRegisterModel) {
    return this.http.post<any>(
      `${environment.apiUrl}${this.registerPatient_api_url}`,
      request,
      this.httpOptions
    );
  }
  getKnownVCareList() {
    return this.http.get<KnownVareResponse>(
      `${environment.apiUrl}${this.knownVCareList_api_url}`,
      this.httpOptions
    );
  }

  savePatientReferral(value: PatientRefferal) {
    return this.http.post<boolean>(
      `${environment.apiUrl}${this.savereferral_api_url}`,
      value,
      this.httpOptions
    );
  }
  UnsubscribeMe(value: any) {
    return this.http.post(
      `${environment.apiUrl}${this.unsubscribe_api_url}`,
      value,
      this.httpOptions
    );
  }

  saveRegLog(request: SaveLogModel) {
    return this.http.post<Number>(
      `${environment.apiUrl}${this.saveRegLog_api_url}`,
      request,
      this.httpOptions
    );
  }
  //#endregion Registration
}
