export const environment = {
  apiUrl: 'https://devapi.vcareurgentcare.com/api',
  // authapiUrl: "https://localhost:7123/api/v1/Authentication/login",
  encryptKey: 'DA9488CB56704D9597F911B3ED479257',
  encryptIV: 'DA9488CB56704D9597F911B3ED479257',
  AuthenticationAPIKey: '14$BB$-*%eG09147&KlqM*',
  gridGlobalSearchLength: 4,
  requestencrypt: false,
  applicationKey: `-----BEGIN PUBLIC KEY-----
  MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA2GstH8VCNYs3OCN9vNuM
  8DJAt6IibTtKKeGH04YT+Pjj5p/xdLgytZgUmNb1d+J2uzZKnzRmOPT7bnZw+Vvq
  fwEqwbuh9J67Wtu/bQLGiCa6zIWEALj+ICQZDOroNFAHZ8Yts1vRVjVWL/yrQywL
  NVwvF6CwT7MgzKpH73h1vyQrHO/6DJ36cnnzLUKm5id+TCIfjwU1f3YL0gPGhLg2
  ax5+QTAuWBpYyLzlY1HgzlQl2VM91oHsLZorMzZj1QfYcXioLrton5I8oq8iyC/Y
  xpV75iL5Jt+J2ARZY5UMxWGjkoZVWre5nRqf4YZwG/uiFsd7G1HI+b/sMCTknGiw
  EQIDAQAB
  -----END PUBLIC KEY-----`,
  production: false,
  api: '',
  DateFormat: 'DD-MMM-YYYY',
  TimeFormat: 'hh:mm A',
  DateTimeFormat: 'DD-MMM-YYYY hh:mm A',
  isJWT: true,
  LoginURL: '/user/login',
  HomeURL: '/patient/dashboard',
  SessionTimeOut: 5,
  barCodeValue: '100749960',
  vcareUrl: 'https://vcareurgentcare.com',
  Encrypt: 'VC@reUrGe@tcAR$',
  HomePath: 'https://test-dev.vcareurgentcare.com',
  VCareUrgentCareLocation: 4,
  GoogleTagManagerId: 'AW-10861334743/wlNTCNKGj4MYENehi7soDummy',
  Config: 'G-BW9BJ88GEH',
  GoogleMapPath: 'https://www.google.com/maps/dir/?api=1&',
};
