<!-- Google Tag Manager (noscript) -->
<noscript
  ><iframe
    src="https://www.googletagmanager.com/ns.html?id=GTM-KLGNZJGB"
    height="0"
    width="0"
    style="display: none; visibility: hidden"
  ></iframe
></noscript>
<!-- End Google Tag Manager (noscript) -->

<div class="max-670">
  <div
    *ngIf="!IsProviderNotMapped && !RescheduleApp"
    class="content-header confirmation-header"
  >
    <i class="ti ti-checklist"></i>
    <h2>Hi {{ PatientName }}, your appointment got confirmed</h2>
    <p>Confirmation details has been sent to your email.</p>
  </div>
  <div *ngIf="RescheduleApp" class="content-header confirmation-header">
    <i class="ti ti-checklist"></i>
    <h2>Hi {{ PatientName }}, your appointment got rescheduled!</h2>
    <p>Confirmation details has been sent to your email.</p>
  </div>
  <div
    *ngIf="IsProviderNotMapped && !RescheduleApp"
    class="content-header confirmation-header"
  >
    <i class="ti ti-z3_clock-exclamation f-40"></i>
    <h2>Hi {{ PatientName }}, We Got Your Information</h2>
    <p>
      We're in the process of confirming your appointment, and we'll be sending
      you a confirmation email once everything is set.<br />
      Thank you for your cooperation!
    </p>
  </div>
  <div class="content-scroll">
    <div class="booking-box appointment-confirmation">
      <div class="booking-box-inner d-flex align-items-center">
        <div class="location-img">
          <img
            src="assets/images/new-brunswik.jpg"
            alt="Vcare Urgent Primary Care"
          />
        </div>
        <div class="location-details">
          <span>{{
            this.AppointmentDetails.ApponitmentOn | date : "dd LLL yyyy hh:mm a"
          }}</span>
        </div>
      </div>
      <div class="appointment-address">
        <h5>{{ VisitDetails }} Visit</h5>
        <p>
          In {{ AppointmentDetails.Location.LocationName }},
          {{ AppointmentDetails.Location.LocationAddress }}
        </p>
      </div>
    </div>
    <div class="appointment-btns" *ngIf="!IsProviderNotMapped">
      <button
        class="calendar-dropdown has-tooltip position-bottom"
        (click)="addToCalendar()"
        id="my-calendar-button"
      >
        <input type="date" />
        <i class="ti ti-calendar-event"></i>
        <span class="tooltip-text">Add to Calendar</span>
      </button>
      
      <button
        class="location-tracking has-tooltip position-bottom"
        (click)="getDirections()"
      >
        <i class="ti ti-map-pin"></i>
        <span class="tooltip-text">Get Directions</span>
      </button>
      <button
        class="action-btn"
        data-bs-toggle="modal"
        data-bs-target="#cancel-popup"
      >
        Cancel
      </button>
      <button class="action-btn" (click)="Reschedule()">Reschedule</button>
    </div>
    <div class="sticky-btn">
      <div class="small-btn">
        <button class="slot-btn" (click)="BackToAppointment()">
          Back to Home <i class="ti ti-arrow-narrow-right"></i>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Provider Popup -->
<div
  class="modal fade appointment-popup cancel-appointment"
  id="cancel-popup"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      >
        <i class="ti ti-x" *ngIf="cancelFlag"></i>
      </button>
      <div class="cancel-content text-center">
        <span class="filled-icon" *ngIf="cancelFlag"
          ><i class="ti ti-calendar-event"></i
        ></span>
        <h5 *ngIf="cancelFlag">
          Please confirm your appointment cancellation.
        </h5>
      </div>
      <div class="confirm-content text-center">
        <span class="filled-icon cancel-icon" *ngIf="!cancelFlag"
          ><i class="ti ti-circle-check"></i
        ></span>
        <h5 *ngIf="!cancelFlag">
          Your scheduled appointment has been cancelled successfully.
        </h5>
      </div>
      <div class="booking-box">
        <div class="booking-box-inner d-flex align-items-center">
          <div class="location-img">
            <img
              src="assets/images/new-brunswik.jpg"
              alt="Vcare Urgent Primary Care"
            />
          </div>
          <div class="location-details">
            <h5>
              {{ AppointmentDetails.CategoryName }}
              {{ AppointmentDetails.VisitReasonName }} Visit
            </h5>
            <span>{{
              AppointmentDetails.ApponitmentOn | date : "LLL dd yyyy, hh:mm a"
            }}</span>
          </div>
        </div>
      </div>
      <div class="cancel-content">
        <button
          class="slot-btn reschedule-btn"
          (click)="Reschedule()"
          *ngIf="cancelFlag"
        >
          Reschedule <i class="ti ti-arrow-narrow-right"></i>
        </button>
        <button
          class="slot-btn cancel-btn"
          (click)="cancelAppointment(AppoitmentId)"
          *ngIf="cancelFlag"
        >
          Cancel Appointment <i class="ti ti-arrow-narrow-right"></i>
        </button>
      </div>
      <div class="confirm-content">
        <button
          class="slot-btn reschedule-btn"
          (click)="Redirect(true)"
          *ngIf="!cancelFlag"
        >
          Book a New Appointment <i class="ti ti-arrow-narrow-right"></i>
        </button>
        <button
          class="slot-btn"
          (click)="Redirect(false)"
          *ngIf="!cancelFlag"
          value="Okay"
        >
          Okay <i class="ti ti-arrow-narrow-right"></i>
        </button>
      </div>
    </div>
  </div>
</div>
