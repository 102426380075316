import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../../_services/auth.service';
import { AlertService } from 'src/app/_shardviews/_alert/alert.service';
import { LoaderService } from 'src/app/_shared/_services/loader.service';
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    errMsg : string ="Something went wrong, Please contact administrator!";
  constructor(private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private loaderService: LoaderService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401) {
        this.authenticationService.logout();
        location.reload();
      }
      if (err.status === 400) {
        this.alertService.warn( err.error !=null ? err.error : this.errMsg );
        if(this.loaderService.getLoaderStatus())
          this.loaderService.setLoaderStatus(false);
      }
      if (err.status === 404) {
        this.alertService.warn( err.error !=null ? err.error : this.errMsg );
        if(this.loaderService.getLoaderStatus())
          this.loaderService.setLoaderStatus(false);
      }
      const error = err.message || err.statusText;
      return throwError(error);
    }))
  }
}