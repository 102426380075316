import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { PatientRegisterModel } from 'src/app/_models/registermodel';
import { SaveLogModel } from 'src/app/_models/registermodel';
import { RegistrationService } from 'src/app/_services/registration.service';
import { AlertService } from 'src/app/_shardviews/_alert';
import { CommonHelper } from 'src/app/_shared/_helpers/CommonHelper';
import { EncryptDecryptService } from 'src/app/_shared/_services/security-service';
import { AppointmentService } from 'src/app/_services/appointment.service';
import { AppointmentModel } from 'src/app/_models/appointmentmodel';
import { BroadCastService } from 'src/app/_shared/_services/broadcast.service';
import { LoaderService } from 'src/app/_shared/_services/loader.service';
import { environment } from '../../../../environments/environment';

declare var $: any;
// declare var jsBarcode: any;

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
})
export class RegistrationComponent {
  @ViewChild('patientDoB', { read: ElementRef })
  public patientDoB!: ElementRef<any>;
  @ViewChild('patientDoB2', { read: ElementRef })
  public patientDoB2!: ElementRef<any>;

  @ViewChild('messageDiv', { read: ElementRef })
  public messageDiv!: ElementRef<any>;
  @Input() calendarDate: any;

  @Input() EmailId: string = '';
  @Input() PostalCode: string = '';

  public date: Date = new Date();


  registerStep1Form!: FormGroup;
  registerStep2Form!: FormGroup;
  loading = false;
  submittedForm1 = false;
  submittedForm2 = false;
  // VisitReasonSubCateogryId!: string;
  step1IsActive = true;
  step2IsActive = false;
  value: string | undefined;
  DOBMaxDate!: Date;
  DOBMinDate!: Date;
  registerModel!: PatientRegisterModel;
  VisitDetails!: string;
  SlotDateDetails!: string;
  SlotTimeDetails!: string;
  patientUserId: number = 0;
  appointmentId: number = 0;
  showAddBarCode: boolean = false;
  barCodeValue: any = environment.barCodeValue;
  visibleclass: string = 'd-none';
  Appointment!: AppointmentModel;
  IsNewPatient: boolean = false;
  Regtype: number = -1;
  location: any;
  // calendarDate: any;
  isScan: any = false;
  isScanUsed: any = false;
  platform: string = '';
  isDOB: any = false;
  isFutureDate: any = false;
  isMinDate: any = false;
  isRegistration: boolean = false;
  categoryName: any;
  booleanValue: any;
  mobileNumber: string = '';
  FutureDateOfBirth: string = "";
  public maxDate = new Date();
  patientData: any;
  savelog!: any;
  LogId: any;
  registrationToAppointment: boolean = false;
  isValidDOB: boolean = true;

  constructor(
    private appointmentService: AppointmentService,
    private registrationService: RegistrationService,
    private alertService: AlertService,
    private encryptionService: EncryptDecryptService,
    private helper: CommonHelper,
    private router: Router,
    private route: ActivatedRoute,
    private title: Title,
    private meta: Meta,
    private broadCastService: BroadCastService,
    private loader: LoaderService,
    private commonHelper: CommonHelper
  ) {
    ////this.broadCastService.setMessage('PageLoad');
    this.registerModel = new PatientRegisterModel();
    this.DOBMaxDate = new Date();
    this.DOBMinDate = this.helper.AddDaysInDate(new Date(), -365 * 150);
    // this.VisitReasonSubCateogryId = '';
    title.setTitle('Patients Registration, Book an Appointment - VCare South Brunswick, NJ');
    this.meta.updateTag({ name: 'description', content: `Patients can register here to Book an Appointment Instantly with VCare Urgent & Primary Care in Brunswick, Dayton, NJ` });
    // this.broadCastService.getMessage//.pipe(first())
    // .subscribe((msg) => {
    //   if (msg === "ShowForm1") {
    //     if (!this.step1IsActive) {
    //       this.step2IsActive = false;
    //       this.step1IsActive = true;
    //     }
    //     else{
    //       // this.broadCastService.setMessage('MoveToPrev');
    //     }

    //   }
    // }
    // );
    this.Appointment = new AppointmentModel();
  }

  get RegisterStep1Form() {
    return this.registerStep1Form.controls;
  }
  get RegisterStep2Form() {
    return this.registerStep2Form.controls;
  }

  ngOnInit() {
    this.savelog = new SaveLogModel();
    this.maxDate = new Date();
    const userAgent = window.navigator.userAgent.toLowerCase();
    if (userAgent.includes('android') || userAgent.includes('iphone') || userAgent.includes('ipad')) {
      this.platform = 'Mobile Browser';
    } else {
      this.platform = 'Web Browser';
    }

    (<any>window).my = (<any>window).my || {};
    (<any>window).my.namespace = (<any>window).my.namespace || {};
    (<any>window).my.namespace.publicFunc = this.publicFunc.bind(this);

    ////this.broadCastService.setMessage('PageLoad');
    this.loader.setLoaderStatus(true);
    this.loader.setLoaderStatus(false);
    let ActiveDaySliderConfigs = localStorage.getItem('ActiveDaySliderConfigs');
    let ActiveSlotDetail = localStorage.getItem('ActiveSlotDetail');
    let _visitReason = localStorage.getItem('_visitReason');
    let visitReasonCategory = localStorage.getItem('visitReasonCategory');
    if (!localStorage.getItem('AppointmentDetails')) {
      this.registrationToAppointment = true;
      localStorage.setItem('registrationToAppointment', String(this.registrationToAppointment));
    }
    localStorage.removeItem('IsServiceRegistration');
    localStorage.removeItem('CategoryName');

    this.route.queryParamMap.subscribe((params) => {
      this.booleanValue = params.get('isServiceRegistration') != null ? params.get('isServiceRegistration') : false;
      this.isRegistration = JSON.parse(this.booleanValue);
      this.categoryName = params.get('category') != null ? params.get('category') : visitReasonCategory;

      if (this.isRegistration) {
        localStorage.removeItem('covidtime');
        localStorage.removeItem('categoryitems');

        this.registrationToAppointment = false;
        localStorage.removeItem('registrationToAppointment');
      }

      if (this.categoryName && this.isRegistration) { localStorage.setItem("CategoryName", this.categoryName); }

      localStorage.setItem('IsServiceRegistration', this.booleanValue.toString());
    });

    localStorage.removeItem('ShowAppointmentAfterRegister');

    if (ActiveDaySliderConfigs && ActiveSlotDetail && !this.isRegistration) {
      this.SlotDateDetails = JSON.parse(
        ActiveDaySliderConfigs
      ).SliderDateInString;
      this.SlotTimeDetails = JSON.parse(ActiveSlotDetail).SlotValue;
    }
    //_visitReason visitReasonCategory
    if (_visitReason && visitReasonCategory && !this.isRegistration) {// && visitReasonSubCategoryId) {
      this.VisitDetails = `${visitReasonCategory} - ${_visitReason}`;
      // this.VisitReasonSubCateogryId = visitReasonSubCategoryId;
      localStorage.setItem('ShowAppointmentAfterRegister', '1');
    }

    this.registerStep1Form = new FormGroup({
      FirstName: new FormControl(this.registerModel.FirstName, [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(
          '^[a-zA-Z\\s]+$'
        )
      ]),
      LastName: new FormControl(this.registerModel.LastName, [
        Validators.required,
        Validators.minLength(1),
        Validators.pattern(
          '^[a-zA-Z\\s]+$'
        )
      ]),
      DateOfBirth: new FormControl(this.registerModel.DOB, [
        Validators.required,
        Validators.pattern(/^\d{2}\/\d{2}\/\d{4}$/
          ///^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
        ),
      ]),
      ContactNumber: new FormControl(this.registerModel.MobileNo, [
        Validators.required,
        Validators.minLength(14),
        Validators.maxLength(14),
        // Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/),
        Validators.required,
        // Validators.pattern("^[0-9]*$"),
      ]),
      Gender: new FormControl(this.registerModel.Gender, [Validators.required]),
    });

    this.registerStep2Form = new FormGroup({
      PostalCode: new FormControl(this.registerModel.PostalCode, [
        Validators.required,
        Validators.minLength(5),
        Validators.pattern('^[0-9]*$'),
      ]),
      EmailId: new FormControl(this.registerModel.Email, [
        Validators.required,
        Validators.pattern(
          '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z ]{2,4}$'
        ),
      ]),
    });

    let flag = localStorage.getItem('ActiveForm');
    if (!flag) localStorage.setItem('ActiveForm', 'Form1');
    else if (flag === 'Form2') {
      this.step1IsActive = false;
      this.step2IsActive = true;
    }

    this.setOnloadData();

    setTimeout(() => {
      this.showtheinformationmodel(false);
    }, 4000);
  }

  removeFirstNameExtraSpaces() {
    const firstNameFieldControl = this.registerStep1Form.get('FirstName');
    if (firstNameFieldControl) {
      const nameValue = firstNameFieldControl.value;
      firstNameFieldControl.setValue(nameValue.replace(/\s{2,}/g, ' '));
    }
  }

  removeLastNameExtraSpaces() {
    const lastNameFieldControl = this.registerStep1Form.get('LastName');
    if (lastNameFieldControl) {
      const nameValue = lastNameFieldControl.value;
      lastNameFieldControl.setValue(nameValue.replace(/\s{2,}/g, ' '));
    }
  }

  ondateupdate($event: any) {
    if ($event.srcElement.value) {
      let dt = new Date($event.srcElement.value);
      if (this.DOBMaxDate >= dt && this.DOBMinDate <= dt) {
        this.RegisterStep1Form['DateOfBirth'].setValue(
          this.helper.GetDateAsString(dt)
        );
        // this.patientDoB2.nativeElement.className = "p-element ng-untouched ng-pristine";
      } else {
        this.alertService.warn('Please enter the valid date of birth.');
        this.calendarDate = '';
        // this.patientDoB2.nativeElement.className = "p-element ng-untouched ng-pristine invalid";
        this.RegisterStep1Form['DateOfBirth'].setValue('');
      }
    }
  }

  publicFunc(data: any) {
    this.Regtype = -1;
    if (data) {
      data.forEach((item: any, index: any) => {
        if (item.desc == 'First Name') {
          this.RegisterStep1Form['FirstName'].setValue(item.value);
          $('#fName').removeClass([
            'ng-untouched',
            'ng-pristine',
            'ng-invalid',
          ]);
          $('#fName').addClass(['ng-touched', 'ng-valid']);
        } else if (item.desc == 'Last Name') {
          this.RegisterStep1Form['LastName'].setValue(item.value);
          // this.registerBO.LastName = item.value;
        } else if (item.desc == 'Date of Birth') {
          let d = new Date(
            item.value.substr(4, 4),
            item.value.substr(2, 2),
            item.value.substr(0, 2)
          );
          var output = item.value;
          output = [output.slice(0, 2), '/', output.slice(2)].join('');
          output = [output.slice(0, 5), '/', output.slice(5)].join('');
          setTimeout(() => {
            this.datechanged(output);
            this.isScan = true;
            this.isScanUsed = true;
            window.document.getElementById('form1Submit')?.click();
          }, 1000);
          // this.registerBO.DisplayDOB = this.utility.ToString(d, true, false);;
        } else if (item.desc == 'Sex') {
          this.RegisterStep1Form['Gender'].setValue(item.value);
          // this.registerBO.Gender = this.getGenderId(item.value)?.toString();
        }
        // else if (item.desc == 'Height in FT_IN') {
        //   this.registerBO.Height = item.value;
        // }
        // else if (item.desc == 'Mailing Street Address1') {
        //   this.registerBO.MailingAddress.Street = item.value;
        // }
        // else if (item.desc == 'Mailing City') {
        //   this.registerBO.MailingAddress.CityName = item.value;
        // }
        else if (item.desc == 'Mailing Postal Code') {
          let postalCode = '';
          if (item.value.length > 5)
            postalCode = item.value.toString().slice(-5);
          else postalCode = item.value;
          this.PostalCode = postalCode;
          this.RegisterStep2Form['PostalCode'].setValue(parseInt(postalCode));
          // this.registerBO.MailingAddress.Zip = item.value;
        }
        // else if (item.desc == 'Weight in LBS') {
        //   this.registerBO.Weight = item.value;
        // }
        // else if (item.desc == 'Mailing Street Address2') {
        //   this.registerBO.MailingAddress.Apt_Suite = item.value;
        // }
      });
    } else {
      //this.utility.Error('Error!');
    }
    $('#BasicInfoForm').removeClass('ng-invalid').addClass('ng-valid');
    $('#btnSubmit').removeAttr('disabled');
    this.closeBarCode();
  }
  testingDatePrint(date: Date) {
    let dt = new Date(date);
    return this.helper.GetDateAsStringWithFormat(dt, 'm/d/y', '/');
  }

  datechanged($event: any) {

    let dateValue = (document.getElementById("date-to") as HTMLInputElement)?.value;
    let dt = new Date(dateValue);
    if (dt) {
      this.calendarDate = dt;
      this.RegisterStep1Form['DateOfBirth'].setValue(
        this.helper.GetDateAsString(dt)
      );
    }
    else {
      this.calendarDate = '';
      this.RegisterStep1Form['DateOfBirth'].setValue(
        ''
      );
      this.alertService.warn('Please enter the valid date of birth.');
      return;
    }
  }

  getformvalue(value: any) {
    return this.RegisterStep1Form[value].value;
  }

  tostringvalue(value: any) {
    return JSON.stringify(value);
  }

  savePatientBasicInfo(request: any) {
    this.registerModel.isScannerUsed = this.isScanUsed;
    this.registerModel.FirstName = this.registerModel.FirstName.trim();
    this.registerModel.LastName = this.registerModel.LastName.trim();
    this.registrationService
      .registerPatient(this.registerModel)
      .subscribe((data) => {
        if (data && data.Error.StatusCode == 200) {
          this.patientUserId = data.Result;
          if (this.IsNewPatient) {
            this.step1IsActive = false;
            this.step2IsActive = !this.step1IsActive;
            localStorage.setItem('Referral', '1');
            localStorage.setItem('PatientId', this.patientUserId.toString());
            localStorage.setItem('ActiveForm', 'Form2');
            this.savelog.UserId = this.patientUserId;
            this.RegistrationSaveLog(this.savelog);
          }
          this.loader.setLoaderStatus(false);
        } else this.alertService.warn('Failed to save patient details.');
      });
  }

  SelectedDOB(dt: any) {
    this.registerModel.DOB = dt;
  }

  RegistrationSaveLog(log: SaveLogModel) {
    try {
      this.registrationService.saveRegLog(log).subscribe((data) => {
        if (data) {
          if (log.Type != 2) {
            this.LogId = data
            localStorage.setItem('LogId', this.LogId.toString());
          }
        }
      });
    }
    catch (e) {
      console.log('Error in appointment log')
    }
  }

  onSubmitForm1() {
    var Isvalid = true;
    if (this.isScan) {
      this.isScan = false;
      //return;
    }
    this.submittedForm1 = true;
    this.DateValidation();
    console.log(this.registerStep1Form);
    if (this.registerStep1Form.invalid || this.isDOB || this.isFutureDate || this.isMinDate) {
      this.loader.setLoaderStatus(false);
      return;
    }
    if ((this.VisitDetails && localStorage.getItem('AppointmentDetails') != null) || this.isRegistration || this.registrationToAppointment) {
      this.isDOB = false;
      this.isMinDate = false;
      this.isFutureDate = false;

      this.loader.setLoaderStatus(true);
      this.registerModel.IsExistingUser = false;
      // reset alerts on submit
      this.alertService.clear();
      // stop here if form is invalid
      this.registerModel.FirstName = this.RegisterStep1Form['FirstName'].value.trim();
      this.registerModel.LastName = this.RegisterStep1Form['LastName'].value.trim();
      this.registerModel.DOB = this.commonHelper.GetDateAsString(new Date(this.RegisterStep1Form['DateOfBirth'].value));
      this.registerModel.MobileNo =
        this.RegisterStep1Form['ContactNumber'].value;
      this.registerModel.Gender = parseInt(
        this.RegisterStep1Form['Gender'].value
      );
      this.registerModel.IsServiceRegistration = this.isRegistration;
      this.registerModel.CategoryName = this.categoryName;
      localStorage.setItem('PatientName', this.registerModel.FirstName);

      this.savelog.FirstName = this.registerModel.FirstName;
      this.savelog.LastName = this.registerModel.LastName;
      this.savelog.Email = "";
      this.savelog.MobileNo = this.registerModel.MobileNo;
      this.savelog.DOB = this.registerModel.DOB;
      this.savelog.Id = this.LogId != undefined ? this.LogId : 0; //Number(localStorage.getItem("LogId")) ? Number(localStorage.getItem("LogId")) : 0;
      this.savelog.Type = 0;

      this.registrationService
        .checkPatientExistance(this.registerModel)
        .subscribe((data) => {
          this.patientData = data;
          if (data.length == 0) {
            this.loader.setLoaderStatus(true);
            // this.RegistrationSaveLog(this.savelog);
            this.IsNewPatient = true;
            this.registerModel.Email = '';
            this.registerModel.IsExistingUser = false;
            this.registerModel.isUpdate = false;
            this.savePatientBasicInfo(this.registerModel);
          } else if (data.length > 0 && !this.patientData[0].Email) {
            this.patientUserId = data[0].UserId;
            this.step1IsActive = false;
            this.step2IsActive = !this.step1IsActive;
            this.loader.setLoaderStatus(false);
            localStorage.setItem('PatientId', this.patientUserId.toString());
            localStorage.setItem('ActiveForm', 'Form2');
          } else {

            this.savelog.Email = this.patientData[0].Email;
            this.savelog.MobileNo = this.patientData[0].Mobile.toString();
            this.savelog.UserId = this.patientData[0].UserId;
            this.savelog.Type = 1;
            this.IsNewPatient = false;
            this.patientUserId = data[0].UserId;
            this.registerModel.IsExistingUser = true;
            this.registerModel.PatientUserId = this.patientUserId;

            if (this.VisitDetails && !this.isRegistration) {
              this.savePatientBasicInfo(this.registerModel);
              this.saveAppointment();
            } else if (this.isRegistration) {
              this.RegistrationSaveLog(this.savelog);
              if (!this.registerModel.isUpdate) { this.savePatientBasicInfo(this.registerModel); }
              setTimeout(() => { this.router.navigate(['registrationsuccess']); }, 350)
            }
            else if (this.registrationToAppointment) {
              this.RegistrationSaveLog(this.savelog);
              localStorage.setItem('PatientId', this.patientUserId.toString());
              setTimeout(() => { this.router.navigate(['appointment']); }, 350)
            }
          }
        });
    } else this.router.navigate(['/appointment']);
    localStorage.setItem(
      'registrationDetails',
      JSON.stringify(this.registerModel)
    );
  }

  updatePatient() {
    this.loader.setLoaderStatus(true);
    var regDetails = localStorage.getItem('registrationDetails');
    let email = regDetails ? JSON.parse(regDetails) : '';
    this.registerModel.Email =
      this.RegisterStep2Form['EmailId'].value != ''
        ? this.RegisterStep2Form['EmailId'].value.toLowerCase().trim()
        : email.Email.trim();
    this.registerModel.PostalCode =
      this.RegisterStep2Form['PostalCode'].value != ''
        ? this.RegisterStep2Form['PostalCode'].value
        : email.PostalCode;
    this.registerModel.isUpdate = true;
    this.IsNewPatient = true;
    localStorage.removeItem('registrationDetails');
    localStorage.setItem(
      'registrationDetails',
      JSON.stringify(this.registerModel)
    );
    this.RegisterStep2Form['EmailId'].setValue(this.registerModel.Email);
    this.RegisterStep2Form['PostalCode'].setValue(
      this.registerModel.PostalCode
    );
    this.EmailId = this.registerModel.Email;
    this.PostalCode = this.registerModel.PostalCode;

    this.savelog.FirstName = this.registerModel.FirstName;
    this.savelog.LastName = this.registerModel.LastName;
    this.savelog.Email = this.registerModel.Email;
    this.savelog.MobileNo = this.registerModel.MobileNo;
    this.savelog.DOB = this.registerModel.DOB;
    this.savelog.Id = this.LogId;

    this.registrationService
      .registerPatient(this.registerModel)
      .subscribe((data) => {
        if (data && data.Error.StatusCode == 200) {
          this.patientUserId = data.Result;
          this.Appointment.UserId = this.patientUserId;

          this.savelog.UserId = data.Result;
          this.savelog.Type = 1;
          this.RegistrationSaveLog(this.savelog);

          if (this.VisitDetails && !this.isRegistration) {
            this.saveAppointment();
          } else if (this.isRegistration || this.registrationToAppointment) {
            if (this.IsNewPatient) this.router.navigate(['/referral']);
          }
        } else this.alertService.warn('Failed to save patient details.');
        this.loader.setLoaderStatus(false);
      });
  }

  onSubmitForm2() {
    this.submittedForm2 = true;
    // reset alerts on submit
    this.alertService.clear();
    // stop here if form is invalid
    if (this.registerStep2Form.invalid) {
      return;
    }
    this.updatePatient();
    localStorage.setItem('Referral', '1');
    localStorage.removeItem("ActiveForm");
    // this.loader.setLoaderStatus(false);
  }

  saveAppointmentDetails() {
    this.loader.setLoaderStatus(true);
    var appointmentDetails = localStorage.getItem('AppointmentDetails');
    if (appointmentDetails != null) {
      this.Appointment = JSON.parse(appointmentDetails);
    }
    else
      this.router.navigate(['appointment-time-slots']);
    this.Appointment.UserId = this.patientUserId;
    this.Appointment.Others = localStorage.getItem("OtherValue") as string;



    let isCovid = localStorage.getItem("visitReasonCategory");
    if (isCovid && isCovid.trim() === 'COVID Testing') {
      let covidReasonList = localStorage.getItem("CovidResonList");
      if (covidReasonList)
        this.Appointment.SubReason = covidReasonList;
    }
    this.appointmentService
      .saveAppointmentDetails(this.Appointment)
      .subscribe((data) => {
        if (data != null && data?.Result?.ID > 0) {
          this.appointmentId = data?.Result?.ID;
          localStorage.setItem('AppointmentId', this.appointmentId.toString());
          if (this.IsNewPatient) this.router.navigate(['/referral']);
          else this.router.navigate(['/appointment-success']);
          this.loader.setLoaderStatus(false);
        }
        else {
          this.loader.setLoaderStatus(false);
          this.alertService.warn('Failed to schedule Appointment.');
        }
      });
  }

  saveAppointment() {
    let IsProviderNotMapped = Boolean(localStorage.getItem('IsProviderNotMapped'));
    if (IsProviderNotMapped) {
      this.saveAppointmentDetails();
      return;
    }
    this.loader.setLoaderStatus(true);
    var appointmentDetails = localStorage.getItem('AppointmentDetails');
    this.Appointment = JSON.parse(
      appointmentDetails == null ? '' : appointmentDetails
    );
    this.Appointment.UserId = this.patientUserId;
    this.Appointment.Others = localStorage.getItem("OtherValue") as string;

    let isCovid = localStorage.getItem("visitReasonCategory");
    if (isCovid && isCovid.trim() === 'COVID Testing') {
      let covidReasonList = localStorage.getItem("CovidResonList");
      if (covidReasonList)
        this.Appointment.SubReason = covidReasonList;
    }
    this.appointmentService
      .saveAppointment(this.Appointment)
      .subscribe((data) => {
        if (data != null && data?.Result?.ID > 0) {

          var _appointmentOn = this.Appointment.AppointmentedOnDate + ' ' + this.Appointment.AppointmentedOnTime;

          this.savelog.AppointmentId = data?.Result?.ID;
          this.savelog.AppointmentOn = this.convertDateTime(_appointmentOn);
          this.savelog.Type = 2;

          this.RegistrationSaveLog(this.savelog);

          this.appointmentId = data?.Result?.ID;
          localStorage.setItem('AppointmentId', this.appointmentId.toString());
          if (this.IsNewPatient) this.router.navigate(['/referral']);
          else this.router.navigate(['/appointment-success']);
          this.loader.setLoaderStatus(false);
        } else {
          this.loader.setLoaderStatus(false);
          this.alertService.warn('Failed to schedule Appointment.');
        }
      });
  }


  convertDateTime(inputDateTime: string): string {
    const inputDate = new Date(inputDateTime);

    const year = inputDate.getFullYear();
    const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
    const day = inputDate.getDate().toString().padStart(2, '0');
    const hours = (inputDate.getHours()).toString().padStart(2, '0');
    const minutes = inputDate.getMinutes().toString().padStart(2, '0');

    const convertedDateTime = `${year}-${month}-${day}T${hours}:${minutes}Z`;
    return convertedDateTime;
  }

  showAddBarCodePage() {
    this.showAddBarCode = true;
    setTimeout(function () {
      var obj: any = $('#barcodeModal');
      obj.modal('toggle');
      setTimeout(() => {
        // jsBarcode.Init();
      }, 200);
    }, 200);
  }
  closeBarCode() {
    var _self = this;
    setTimeout(function () {
      var obj: any = $('#barcodeModal');
      obj.modal('toggle');
      _self.showAddBarCode = false;
      //  _self.Regtype = 0;
      setTimeout(() => {
        // jsBarcode.close();
      }, 300);
    }, 400);
  }

  setOnloadData() {
    let storageItem = localStorage.getItem('registrationDetails');
    let registerModel = storageItem ? JSON.parse(storageItem) : '';

    if (registerModel) {
      this.RegisterStep1Form['FirstName'].setValue(registerModel.FirstName);
      this.RegisterStep1Form['LastName'].setValue(registerModel.LastName);
      this.RegisterStep1Form['Gender'].setValue(
        registerModel.Gender.toString()
      );
      this.RegisterStep1Form['ContactNumber'].setValue(this.commonHelper.UpdateUSPhoneNumberFormat(registerModel.MobileNo));

      this.registerModel = registerModel;
      this.DateValidation(registerModel.DOB.replaceAll('-', '/'));

      this.isScan = true;
      this.isScanUsed = true;
      window.document.getElementById('form1Submit')?.click();
    }
  }
  GetMyDateFormat(date: Date) {
    if (date) return this.helper.GetDateAsStringWithFormat(date);
    return null;
  }

  toggleForm() {
    localStorage.setItem('ActiveForm', 'Form1');
    this.step1IsActive = true;
    this.step2IsActive = false;
    this.setOnloadData();
  }

  showtheinformationmodel(type: boolean = true) {
    if (this.messageDiv && this.messageDiv.nativeElement) {
      if (!type) {
        this.messageDiv.nativeElement.className = `${this.messageDiv.nativeElement.className} scan-hidden`;
      } else {
        this.messageDiv.nativeElement.className =
          this.messageDiv.nativeElement.className.replaceAll('scan-hidden', '');
        setTimeout(() => {
          this.showtheinformationmodel(false);
        }, 4000);
      }
    }
  }

  DateValidation(event?: any) {
    let HasVal = event ? event : (document.getElementById("date-to") as HTMLInputElement)?.value;
    let inputDate: any;
    if (HasVal) {
      inputDate = new Date(HasVal);
      HasVal = this.commonHelper.GetDateAsStringWithSpecificDividerUSFormat(inputDate, '/');
    }
    // let currentDate = new Date().toLocaleDateString();
    let currentDate = this.commonHelper.GetDateAsStringWithSpecificDividerUSFormat(new Date(), '/');
    let minDate = this.commonHelper.GetDateAsStringWithSpecificDividerUSFormat(this.helper.AddDaysInDate(new Date(), -365 * 150), '/');
    // let minDate = this.helper.AddDaysInDate(new Date(), -365 * 150).toLocaleDateString();
    let dobFlag = this.checkValidDate(HasVal);
    if (!dobFlag) {
      this.isMinDate = true;
      this.calendarDate = '';
      this.RegisterStep1Form['DateOfBirth'].setValue(
        ''
      );
      return;
    }

    if (HasVal) {
      this.isDOB = false;
      this.isFutureDate = Date.parse(HasVal) > Date.parse(currentDate);
      this.isMinDate = Date.parse(HasVal) < Date.parse(minDate);
      if (!Date.parse(HasVal))
        this.isMinDate = true;
    }
    else {
      this.isDOB = true;
      this.isFutureDate = false;
      this.isMinDate = false;
    }
    this.calendarDate = inputDate;
    this.RegisterStep1Form['DateOfBirth'].setValue(
      HasVal
    );
  }

  checkValidDate(value: any) {
    let dateArray = new Array<string>();
    let thirtyDaysMonth = [4, 6, 9, 11];
    let flag = false;
    if (value)
      dateArray = value.toString().split('/');
    if (dateArray && dateArray.length > 0) {
      for (var i = 0; i < dateArray.length; i++) {
        switch (i) {
          case 0://month
            flag = Number(dateArray[i]) > 0 && Number(dateArray[i]) <= 12;
            break;
          case 1://day
            if (Number(dateArray[0]) === 2) {
              if ((Number(dateArray[2]) % 4) === 0)
                flag = Number(dateArray[i]) > 0 && Number(dateArray[i]) <= 29;
              else
                flag = Number(dateArray[i]) > 0 && Number(dateArray[i]) <= 28;
            } else if (thirtyDaysMonth.findIndex(x => x === Number(dateArray[0])) > -1)
              flag = Number(dateArray[i]) > 0 && Number(dateArray[i]) <= 30;
            else
              flag = Number(dateArray[i]) > 0 && Number(dateArray[i]) <= 31;
            break;
          case 2://year
            flag = Number(dateArray[i]) >= this.DOBMinDate.getFullYear() && Number(dateArray[i]) <= this.DOBMaxDate.getFullYear();
            break;
          default:
            return flag;
        }
        if (!flag)
          return flag;
      }
    }
    return flag;
  }

  //
  CheckPhoneFormat() {
    let phone = this.RegisterStep1Form['ContactNumber'].value;
    this.RegisterStep1Form['ContactNumber'].setValue(this.commonHelper.UpdateUSPhoneNumberFormat(phone));
  }
}
