import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';


@Directive({
  selector: '[vCareNumericMask]'
})
export class NumericMaskDirective {

  @Input() vCareNumericMask = '';

  @Input() vCareNumericMaxLength = '';

  constructor(private el: ElementRef
  ) {

  }

  @HostListener('keyup', ['$event']) public onKeyup(event: KeyboardEvent): void {
    const value = (event.target as HTMLInputElement).value;
    let matches = value.match(/(\d+)/);
    if (matches && matches.length > 0) {
      let str = value.replaceAll(matches![0], '');
      this.el.nativeElement.value = this.el.nativeElement.value.replaceAll(str, '');
    } else {
      this.el.nativeElement.value = this.el.nativeElement.value.replaceAll(value, '');
    }

  }

}


