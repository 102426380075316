import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';


@Directive({
  selector: '[vCarePhoneMask]'
})
export class PhoneMaskDirective {

  @Input() vCarePhoneMask = '';

  @Input() IsBackSpacePressed: any;

  @Input() OldValue: any;

  constructor(private el: ElementRef,
    private _phoneControl: NgControl
  ) { }

  @HostListener('keyup', ['$event']) public keyup(event: KeyboardEvent): void {

    const skipKeys = [37, 38, 39, 40, 16, 17, 18];
    if (skipKeys.indexOf(event.keyCode) > -1 || skipKeys.indexOf(event.which) > -1) {
      return;
    }

    let updateBackSpace = true;

    let ele = (this.el.nativeElement as HTMLInputElement);
    let isneedcurmove = -1;
    if (!ele) {
      return;
    }

    const value = this.el.nativeElement.value;
    let phonenumber = value.trim();
    //console.log(` phonenumber ${phonenumber}`);
    let valueofEvent = event.key;
    if (phonenumber && !isNaN(parseInt(valueofEvent))) {

      const keycode = event.code.toLowerCase() || event.which;
      let backspacekeycode = "backspace";
      let deletespacekeycode = "delete";
      let deleteCode = 8;


      let insertPosi = ele.selectionStart;
      insertPosi = insertPosi ? insertPosi : 0;
      let maskphoneNumber = this.OldValue;


      if (phonenumber.length > insertPosi && !(deleteCode == event.which || backspacekeycode === keycode || deletespacekeycode === keycode)) {

        if (phonenumber[insertPosi] === ")")
          isneedcurmove = insertPosi + 2;
        else if (phonenumber[insertPosi] === "-")
          isneedcurmove = insertPosi + 1;
        else
          isneedcurmove = insertPosi === 0 ? insertPosi + 1 : insertPosi;

        if (!this.IsBackSpacePressed)
          phonenumber = maskphoneNumber.substring(0, insertPosi) + valueofEvent + maskphoneNumber.substring(insertPosi);
        else {
          phonenumber = phonenumber; 
          updateBackSpace = phonenumber.length===14;
          isneedcurmove = insertPosi-1;
        } 
        //console.log(` Lenght ${phonenumber.length} -> phonenumber ${phonenumber}, Vaue Add Pos -${insertPosi}`) 
      }
      else {
        isneedcurmove = -1;
      }

      phonenumber = this.getNumberAlone(phonenumber);

      if (phonenumber.length > 10) {
        phonenumber = phonenumber.substring(0, 10);
      }
      let checknumber = (/^\d*\.?\d*$/.test(phonenumber));

      if (checknumber && phonenumber.indexOf('.') === -1) {
        if ((deleteCode == event.which || backspacekeycode === keycode || deletespacekeycode === keycode) && phonenumber.length <= 3) {
          this.el.nativeElement.value = "(" + phonenumber;
        }
        else {

          if ((deleteCode == event.which || backspacekeycode === keycode || deletespacekeycode === keycode)) {
            isneedcurmove = insertPosi - 1;
          }  

          let finalValue = '';

          let firstpart = '', secondpart = '', thirdpart = '';

          if (phonenumber && phonenumber.length >= 3) {

            if (phonenumber.length >= 3)
              firstpart = phonenumber.substr(0, 3);
            if (phonenumber.length >= 4) {
              let length = phonenumber.length > 6 ? 3 : phonenumber.length - 3;
              secondpart = phonenumber.substr(3, length);
            }
            if (phonenumber.length > 6)
              thirdpart = phonenumber.substr(6, phonenumber.length - 4);

            if (firstpart && !secondpart && !thirdpart)
              finalValue = `(${firstpart})`;

            //console.log(` Lenght ${phonenumber.length} <-> phonenumber ${phonenumber} 1) finalValue - ${finalValue}`);
            if (secondpart && !thirdpart)
              finalValue = `(${firstpart}) ${secondpart}`;

            //console.log(` Lenght ${phonenumber.length} <-> phonenumber ${phonenumber} 2) finalValue - ${finalValue}`);
            if (thirdpart)
              finalValue = `(${firstpart}) ${secondpart}-${thirdpart}`;

            //console.log(` Lenght ${phonenumber.length} <-> phonenumber ${phonenumber} 3) finalValue - ${finalValue}`);
            ele.value = finalValue;

            if (isneedcurmove >= 0)
              ele.setSelectionRange(isneedcurmove + 1, isneedcurmove + 1);

            //console.log(`this.el.nativeElement.value  ${this.el.nativeElement.value} (isneedcurmove - ${isneedcurmove}) finalValue - ${finalValue}`);

          }

        }
      }
      else {

        let matches = phonenumber.match(/(\d+)/);
        if (matches && matches.length > 0) {
          let str = phonenumber.replaceAll(matches![0], '');
          this.el.nativeElement.value = this.el.nativeElement.value.replaceAll(str, '');
          ////console.log(`this.el.nativeElement.value  ${this.el.nativeElement.value} 1 `);

        } else {
          this.el.nativeElement.value = this.el.nativeElement.value.replaceAll(phonenumber, '');
          ////console.log(`this.el.nativeElement.value  ${this.el.nativeElement.value} 2 `);

        }
      }
      if (updateBackSpace)
        this.IsBackSpacePressed = false;
    }
    else {
      this.IsBackSpacePressed = true;
      if (isNaN(parseInt(valueofEvent))) {
        let mypos = ele.selectionStart;
        // mypos=mypos?mypos-1:0;
        this.el.nativeElement.value = this.el.nativeElement.value.replaceAll(valueofEvent, '');
        ele.setSelectionRange(mypos, mypos);
      } else
        this.el.nativeElement.value = this.el.nativeElement.value.trim();
      ////console.log(`this.el.nativeElement.value  ${this.el.nativeElement.value} 3 `);

    }

    this.OldValue = this.el.nativeElement.value;
  }

  getNumberAlone(phonenumber: string) {
    phonenumber = phonenumber.replaceAll('(', '');
    phonenumber = phonenumber.replaceAll(')', '');
    phonenumber = phonenumber.replaceAll(' ', '');
    phonenumber = phonenumber.replaceAll('-', '');
    ////console.log(` After Removed phonenumber ${phonenumber}`)
    return phonenumber;
  }

}


