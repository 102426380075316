
{{alerts}}
<!-- <div *ngFor="let alert of alerts" class="{{cssClass(alert)}}">
    <a class="close" (click)="removeAlert(alert)">&times;</a>
    <span [innerHTML]="alert.message"></span>
</div> -->

<!-- Success Message Start -->
<div *ngFor="let alert of alerts" class="{{cssClass(alert)}}">
    
    <div class="msg-body">
        <i class="ti {{AlertTypeForTitle}}"></i>
        <span [innerHTML]="alert.message"></span>
        <button class="msg-close btn-close" (click)="removeAlert(alert)"><i class="ti ti-x"></i></button>
    </div>  
  </div>
  <!-- Success Message End -->
<!--   
  <div class="popup-msg info-msg d-none">
    <div class="msg-title d-flex align-items-center justify-content-between">
      <h6 class="d-flex align-items-center mb-0">
        <i class="ti ti-message-2"></i>
        Info
      </h6>
      <button class="msg-close btn-close"><i class="ti ti-x"></i></button>
    </div>
    <div class="msg-body">
      Appointment has been submitted successfully
    </div>  
  </div>
  <div class="popup-msg warning-msg d-none">
    <div class="msg-title d-flex align-items-center justify-content-between">
      <h6 class="d-flex align-items-center mb-0">
        <i class="ti ti-file-alert"></i>
        Warning
      </h6>
      <button class="msg-close btn-close"><i class="ti ti-x"></i></button>
    </div>
    <div class="msg-body">
      Appointment has been submitted successfully
    </div>  
  </div>
  <div class="popup-msg danger-msg d-none">
    <div class="msg-title d-flex align-items-center justify-content-between">
      <h6 class="d-flex align-items-center mb-0">
        <i class="ti ti-file-info"></i>
        Danger
      </h6>
      <button class="msg-close btn-close"><i class="ti ti-x"></i></button>
    </div>
    <div class="msg-body">
      Appointment has been submitted successfully
    </div>  
  </div> -->