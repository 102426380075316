import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment.dev';

import {
  loginRequest,
  loginResponse,
} from '../_models/auth/loginRequestResponse';
import { concat } from 'lodash';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<loginResponse>;
  public currentUser: Observable<loginResponse>;

  constructor(private http: HttpClient) {
    const currentUser = localStorage.getItem('currentUser');
    this.currentUserSubject = new BehaviorSubject<loginResponse>(
      currentUser ? JSON.parse(currentUser) : null
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): loginResponse {
    return this.currentUserSubject.value;
  }

  public get currentUserAuthToken(): string {
    return this.currentUserSubject.value.token;
  }

  public get currentUserRefershToken(): string {
    return this.currentUserSubject.value.reftoken;
  }
  public get isAdminUser(): boolean {
    return this.currentUserSubject.value.isAdmin;
  }
  public get currentUserid(): number {
    return this.currentUserSubject.value.id;
  }
  public get currentUsername(): string {
    return this.currentUserSubject.value.companyName;
  }
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  getsample() {}
  // login(username: any, password: any) {
  //     var authurl = environment.authapiUrl;
  //     console.log(authurl);
  //     this.getsample();
  //     var body = {
  //         "userName": username,
  //         "password": password
  //     };
  //     return this.http.post<any>(authurl, body, this.httpOptions)
  //         .pipe(catchError(
  //             (err: any, caught: Observable<any>) => {
  //                  return this.handleError(err, caught);
  //          }),
  //          map(user => {
  //             this.assignTokenLocalStorageData(user.Data);
  //         })
  //         );
  // }

  handleError(error: any, caught: Observable<any>) {
    return throwError(error);
  }

  assignTokenLocalStorageData(user: any) {
    const vari = user;
    localStorage.setItem('currentUser', JSON.stringify(vari));
    this.currentUserSubject.next(vari);
    return vari;
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem('currentUser');
    const user = new loginResponse();
    this.currentUserSubject.next(user);
  }
}
