import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BroadCastService {

  LastMessage = "";
  private message = new BehaviorSubject<string>('....');
  getMessage = this.message.asObservable();

  constructor() { }


  sendMessage(message: string) {

    if (message === "MoveToPrev" && this.LastMessage!=="MoveToPrev") {
      this.LastMessage = message;
      this.message.next(message);
    }

    if(message !=="MoveToPrev") {
      this.message.next(message);
      this.LastMessage = "";
    }


  }

}
