// txtUserName=asd&txtPassword=asdsd&chkRememberMe=on

export class loginRequest { 
    userName!: string;
    password!: string;     
}


export class loginResponse {
    // userName!: string;
     firstName!: string;
     lastName!: string;
     token!: string;
     companyName!: string;
     contactNumber!: string;
     numberOfEmployees!: number;
     email!: string;
     reftoken!: string;
     id!: number;
     isAdmin!: boolean;
     isSubscribetoPromotional!: boolean;
 }
 