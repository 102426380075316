<p-inputMask #inputMask
             (onBlur)="onBlur()"
             (onComplete)="onComplete()"
             (onInput)="onInput()"
             [(ngModel)]="dateFmt.formatted"
             [disabled]="disabled"
             [inputId]="inputId"
             [mask]="dateFmt.mask"
             [readonly]="readonly"
             placeholder="mm/dd/yyyy"             
             slotChar="mm/dd/yyyy"
             [style]="maskStyle" autocomplete="new-password" [tabindex]="tabindex">
</p-inputMask>
<label>Date of Birth <span class="required">*</span></label>
