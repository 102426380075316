<a browserBackAction class="back-btn">
  <i class="ti ti-arrow-narrow-left"></i>
</a>
<div class="max-670">
  <div class="content-header">
    <h2>{{ChoosenCategory}}</h2>
    <p>Schedule an Appointment for <span>{{ChoosenSubCategory}}</span> at Liberty Mall Dayton
    </p>
    <!-- <h2>Reschedule appointment for Occupational health care</h2>
      <p>Schedule an Appointment for Annual Physical Exam at Liberty Mall Dayton </p> -->
  </div>
  <div class="content-scroll">
    <div class="category-search"
      *ngIf="this.ProviderCollection.length !== 0 && ChoosenCategory!='COVID Testing' && ChoosenCategory!='Urgent Care'&& ChoosenCategory!='urgent care' ">
      <div class="select-provider" data-bs-toggle="modal" data-bs-target="#provider-popup">
        <div *ngIf="this.IsProviderChosen">
          <span class="position-icon left-icon">
            <img src="{{CurrentRecommendedProviderModel.ImagePath}}" alt="{{CurrentRecommendedProviderModel.Name}}">
          </span>
          <div class="provider-content">
            <span class="provider-hint" *ngIf="CurrentRecommendedProviderModel.IsRecommended">Recommended</span>
            <div class="provider-details">
              <h5>{{CurrentRecommendedProviderModel.Name}}</h5>
            </div>
          </div>
        </div>
        <div *ngIf="!this.IsProviderChosen">
          <div class="provider-details">
            <h5>Choose your preferred provider</h5>
          </div>
          <span class="position-icon right-icon">
            <i class="ti ti-chevron-down"></i>
          </span>
          <!-- <span class="position-icon right-icon">
            <i class="ti ti-chevron-down"></i>
          </span> -->
        </div>
      </div>
      <div *ngIf="this.IsProviderChosen" class="provider-delete">
        <span class="position-icon right-icon" (click)="ClearProvider()">
          <i class="ti ti-x"></i>
        </span>
      </div>

    </div>
    <div class="category-search"
      *ngIf="this.ProviderCollection.length === 0 && ChoosenCategory!='COVID Testing' && ChoosenCategory!='Urgent Care' && ChoosenCategory!='urgent care'">
      <div class="select-provider">
        No Providers at the moment..
      </div>
    </div>

    <div class="appointment-calendar">
      <div class="calendar-top">
        <div class="calendar-dropdown">
          <h5>{{ActiveDaySliderConfigs.MonthName}} {{ActiveDaySliderConfigs.SliderDateInString | date:'yyyy'}}</h5>
          <p-calendar [ngModel]="_SliderDate" (onSelect)="changeDate($event)" [disabledDates]="_InvalidNADates"
            [maxDate]="_MaxSliderDate" [minDate]="_MinSliderDate" [showIcon]="true">
          </p-calendar>

        </div>
      </div>
      <div class="calendar-dates">
        <button class="prev-month" class="{{preButtonClassName}}" (click)="moveFutureDays(0)"><i
            class="ti ti-z_chevron-left"></i></button>
        <ul #panel>
          <li *ngFor="let day of DaySliderConfigs; index as i;" class="{{day.CSSClass}}">
            <div (click)="GetSlotDetails(i,$event)">
              <a id="slotday{{i}}">
                <span class="calendar-day">{{day.DayName.substr(0,3)}}</span>
                <span class="calendar-date">{{day.Value}}</span>
                <span class="calendar-time">{{day.SlotValue}}</span>
              </a>
            </div>
          </li>

        </ul>
        <button class="next-month" class="{{nextButtonClassName}}" (click)="moveFutureDays(1)"><i
            class="ti ti-z_chevron-right"></i></button>
      </div>
      <div class="placeholder-loader">
        <ul>
          <li class="placeholder-glow"><span class="placeholder"></span></li>
          <li class="placeholder-glow"><span class="placeholder"></span></li>
          <li class="placeholder-glow"><span class="placeholder"></span></li>
          <li class="placeholder-glow"><span class="placeholder"></span></li>
          <li class="placeholder-glow"><span class="placeholder"></span></li>
          <li class="placeholder-glow"><span class="placeholder"></span></li>
          <li class="placeholder-glow"><span class="placeholder"></span></li>
        </ul>
      </div>
      <div *ngIf="!isPageLoadSlots">
        <div class="calendar-slots" *ngIf="ActiveDaySliderConfigs.Slots.length > 0">
          <div class="slot-session" *ngIf="ActiveDaySliderConfigs.MorningSlots.length > 0">
            <h6>
              <i class="ti ti-z2_sunrise"></i>Morning Slots
              <span><i class="ti ti-chevron-down"></i></span>
            </h6>
            <ul>
              <li *ngFor="let slot of ActiveDaySliderConfigs.MorningSlots; index as i;" class="{{slot.CSSClass}}"
                (click)="UpdateSlotInformation(ActiveDaySliderConfigs,slot,i)">
                <a id="{{slot.Id}}">{{slot.SlotValue}}</a>
              </li>
            </ul>
          </div>
          <div class="slot-session" *ngIf="ActiveDaySliderConfigs.AfternoonSlots.length > 0">
            <h6>
              <i class="ti ti-z2_sun"></i>Afternoon Slots
              <span><i class="ti ti-chevron-down"></i></span>
            </h6>
            <ul>
              <li *ngFor="let slot of ActiveDaySliderConfigs.AfternoonSlots; index as i;" class="{{slot.CSSClass}}"
                (click)="UpdateSlotInformation(ActiveDaySliderConfigs,slot,i)">
                <a id="{{slot.Id}}">{{slot.SlotValue}}</a>
            </ul>
          </div>
          <div class="slot-session" *ngIf="ActiveDaySliderConfigs.EveningSlots.length > 0">
            <h6>
              <i class="ti ti-z2_sunset"></i>Evening Slots
              <span><i class="ti ti-chevron-down"></i></span>
            </h6>
            <ul>
              <li *ngFor="let slot of ActiveDaySliderConfigs.EveningSlots; index as i;" class="{{slot.CSSClass}}"
                (click)="UpdateSlotInformation(ActiveDaySliderConfigs,slot,i)">
                <a id="{{slot.Id}}">{{slot.SlotValue}}</a>
            </ul>
          </div>
          <!-- <ul>
          <li *ngFor="let slot of ActiveDaySliderConfigs.Slots; index as i;" class="{{slot.CSSClass}}"
            (click)="UpdateSlotInformation(ActiveDaySliderConfigs,slot,i)">
            <a id="{{slot.Id}}">{{slot.SlotValue}}</a>
          </li>
        </ul> -->
        </div>
        <div class="slot-section" *ngIf="ActiveDaySliderConfigs.Slots.length === 0">
          <div class="no-slots text-center">
            <h6>No Slots available for Selected Date.</h6>
            <p>
              Please select other date or call us on (888) 460 1151 for all Primary care Appointment Booking.
              For Urgent Care walk-in anytime at the facility between Mon to Fri (8 am to 8 pm) and Sat and Sun (8 am to
              5
              pm).
              Any life-threatening emergencies, please call 911.
            </p>
          </div>
        </div>
        <div class="next-slot" *ngIf="NextAvailableSlotDetails && NextAvailableSlotDetails.SliderDateInString">
          <button class="slot-btn" (click)="MoveNextAvailableSlot(NextAvailableSlotDetails)">
            <span>Next Available Slot</span>
            {{NextAvailableSlotDetails.SliderDateInString | date:'fullDate' }}
            <i class="ti ti-arrow-narrow-right"></i></button>
        </div>
      </div>
    </div>
    <div class="sticky-btn sticky-slot" *ngIf="ActiveSlotDetail && ActiveDaySliderConfigs.Slots.length > 0">
      <div class="d-flex justify-content-between align-items-center max-670">
        <div class="schedule-time">
          <h6>{{ActiveDaySliderConfigs.SliderDateInString | date:'dd LLL yyyy'}} {{ActiveSlotDetail.SlotValue}}</h6>
          <span>Selected Time Slot</span>
        </div>
        <button class="slot-btn" (click)="BookAppointment()" *ngIf="!AppointmentId">Schedule <i class="ti ti-arrow-narrow-right"></i></button>
        <button class="slot-btn" (click)="BookAppointment()" *ngIf="AppointmentId">Reschedule <i class="ti ti-arrow-narrow-right"></i></button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade appointment-popup" id="provider-popup" tabindex="-1" aria-labelledby="exampleModalLabel"
  aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i
          class="ti ti-x"></i></button>
      <h5>Choose your preferred provider</h5>
      <ul>
        <!-- <li  *ngFor="let appointment of visitreasonservicesubwithslot  | searchVisitReasonFilter: SearchReason; index as i;"> -->
        <li *ngFor="let provider of ProviderCollection; index as i;">
          <a (click)="onSelectProvider(provider)">
            <span class="position-icon left-icon">
              <img src="{{provider.ImagePath}}" alt="{{provider.Name}}">
            </span>
            <div class="provider-details">
              <h5>{{provider.Name}}</h5>
              <div class="provider-description">
                <p>{{provider.Description}}</p>
              </div>
            </div>
          </a>
          <span class="expand-btn" *ngIf="provider.Description.length > 100">...</span>
          <span class="provider-hint" *ngIf="provider.IsRecommended">Recommended</span>
        </li>
        <!-- ImagePath!: string;
          Description!: string;
          Id!: number;
          Name!: string;
          Slot!: string;
          IsRecommended!:boolean; -->
      </ul>
    </div>
  </div>
</div>