import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PageNotFoundService {

  private loading: boolean = false;

  constructor() { }

  setPageStatus(loading: boolean) {
    this.loading = loading;
  }

  getPageStatus(): boolean {
    return this.loading;
  }
}