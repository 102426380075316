import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputMask } from 'primeng/inputmask';
import { DateFormatter } from './date-formatter.model';
import { CommonHelper } from 'src/app/_shared/_helpers/CommonHelper';

/**
 * Zapozddření komponenty p-inputMask pro datum a čas dle formátu
 */
@Component({
  selector: 'datetime-mask',
  templateUrl: './datetime-mask.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatetimeMaskComponent),
      multi: true
    }
  ]
})
export class DatetimeMaskComponent {
 
  @Input()
  public disabled!: boolean;
 
  @Input()
  public inputId!: string;
 
  @Input()
  public readonly!: boolean;
 
  @Input()
  public maskStyle: any;

  @Input()
  public tabindex: any;
 
  private __dateFmt: DateFormatter = new DateFormatter();
 
  private __inputMaskViewChild: InputMask | undefined;
 
  private __onModelChange: (args?: any[]) => void = () => { };
 
  private __onModelTouched: (args?: any[]) => void = () => { };
 
  public get dateFmt(): DateFormatter {
    return this.__dateFmt;
  }
 
  @Input()
  public set format(newValue: string) {
    this.__dateFmt.format = newValue;
  }
 
  public get value(): Date {
    return this.__dateFmt.date;
  }
 
  @Input()
  public set value(newValue: Date) {
    this.writeValue(newValue);
  }

  /**
   * Setter reference instance InputMask
   */
  @ViewChild('inputMask', { static: true })
  public set inputMaskViewChild(newValue: InputMask) {
    this.__inputMaskViewChild = newValue;
  }

  /**
   * Obsluha události po opuštění InputMask
   */
  public onBlur(): void {
    this.__onModelTouched();
  }

  /**
   * Obsluha události po vyplnění InputMask
   */
  public onComplete(): void {
    let dateIn = ((document.getElementById("date-to") as HTMLInputElement).value).replaceAll('/', '');
    let dateFromElement = (this.commonHelper.GetDateAsStringWithSpecificDividerUSFormat(this.__dateFmt.date, '/')).replaceAll('/', '');
    if (dateIn === dateFromElement)
      this.updateModel(this.__dateFmt.date);
    else
      this.updateModel(new Date(dateIn));
  }

  /**
   * Obsluha události po změně vstupu InputMask
   */
  public onInput(): void {
    if (this.__inputMaskViewChild?.value === this.__dateFmt.format) {
      this.__dateFmt.date !== null && this.updateModel('');
    }
  }
 
  public registerOnChange(fn: (args?: any[]) => void): void {
    this.__onModelChange = fn;
  }
 
  public registerOnTouched(fn: (args?: any[]) => void): void {
    this.__onModelTouched = fn;
  }
 
  public setDisabledState(val: boolean): void {
    this.__inputMaskViewChild && (this.__inputMaskViewChild.disabled = val);
  }

 
  public updateModel(updatedValue: any): void { 
    this.__onModelChange(((this.__dateFmt.date = updatedValue) as any));
  }
 
  public writeValue(value: Date): void {
    if (!this.__inputMaskViewChild) {
      return;
    }
    if ((undefined === value || null === value || value instanceof Date) && this.__dateFmt.date !== value) {
      this.__dateFmt.date = value;
    }
  }

  constructor(private commonHelper: CommonHelper) { }

}
