
export class DateFormatter {

  private __format: string = 'MM/dd/yyyy'; // hh:mm:ss

  private __fmtRegExp: RegExp = this.__formatToRegExp(this.__format);

  public get d(): number {
    return this.__date!?.getDate();
  }

  public set d(newValue: number) {
    this.__date?.setDate(newValue);
  }

  public get M(): number {
    return this.__date!?.getMonth() + 1;
  }

  public set M(newValue: number) {
    this.__date?.setMonth(newValue - 1);
  }

  public get y(): number {
    return this.__date!?.getFullYear();
  }

  public set y(newValue: number) {
    this.__date?.setFullYear(newValue);
  }

  public get h(): number {
    return this.__date!?.getHours();
  }

  public set h(newValue: number) {
    this.__date?.setHours(newValue);
  }

  public get m(): number {
    return this.__date!?.getMinutes();
  }

  public set m(newValue: number) {
    this.__date?.setMinutes(newValue);
  }

  public get s(): number {
    return this.__date!?.getSeconds();
  }

  public set s(newValue: number) {
    this.__date?.setSeconds(newValue);
  }

  public get u(): number {
    return this.__date!?.getMilliseconds();
  }
  public set u(newValue: number) {
    this.__date?.setMilliseconds(newValue);
  }
  public get date(): Date {
    return this.__date;
  }

  public set date(newValue: Date) {
    this.__date = newValue
  }

  public get format(): string {
    return this.__format;
  }

  public set format(newValue: string) {
    this.__fmtRegExp = this.__formatToRegExp(this.__format = newValue);
  }

  public get formatted(): string {
    if (!this.__date) {
      return '';
    }
    return this.__format.replace(/d+/, (x: string) => `${this.d}`.padStart(x.length, '0'))
      .replace(/M+/, (x: string) => `${this.M}`.padStart(x.length, '0'))
      .replace(/y+/, (x: string) => `${this.y}`.padStart(x.length, '0'))
      .replace(/h+/, (x: string) => `${this.h}`.padStart(x.length, '0'))
      .replace(/m+/, (x: string) => `${this.m}`.padStart(x.length, '0'))
      .replace(/s+/, (x: string) => `${this.s}`.padStart(x.length, '0'))
      .replace(/u+/, (x: string) => `${this.u}`.padStart(x.length, '0'));
  }

  public set formatted(newValue: string) {
    const match = newValue.match(this.__fmtRegExp);
    if (match && match?.groups) {
      !this.__date && (this.__date = new Date());
      Object.entries(match.groups).forEach(([key, value]: [string, string]) => {
        (this as any)?.[key] && ((this as any)[key] = +value);
      });
    }
  }

  public get mask(): string {
    return this.__format.replace(/([dMyhmsu]+)/g, (x: string) => `${'9'.repeat(x.length)}`);
  }

  public constructor(private __date: Date=new Date()) {
  }

  private __formatToRegExp(format: string): RegExp {
    const pattern: string = format.replace(/\\/g, '\\\\').replace(/\//g, '\\/')
      .replace(/\./g, '\\.').replace(/\:/g, '\\:')
      .replace(/([dMyhmsu]+)/g, (x: string) => `(?<${x[0]}>${'\\d'.repeat(x.length)})`)
      .replace(/\s/g, '\\s');
    return new RegExp(pattern);
  }

}
