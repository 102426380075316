<div class="max-670">
    <div class="registeration-success">
        <div class="content-header">
        <i class="ti ti-check"></i>
            <h2>You have been successfully registered !</h2>
        </div>
        <div class="registeration-info">
            <h5>Dear {{PatientName}},</h5>
            <p>Thank you for registering with <span class="registeration-home-title">VCare Urgent &amp; Primary Care.</span> We appreciate your trust in our services and look forward to assisting you with <strong>{{categoryName}}</strong> needs.</p>
            <p>To confirm your appointment, our Front Desk team will be contacting you shortly to finalize the date and time that best suits your schedule. If you have any specific preferences or requirements, please feel free to inform our staff during the call, and we will do our utmost to accommodate them.</p>
            <p>Wishing you good health</p>
            <div class="regards-info">
                <p>Thanks, <br>Vcare Team</p>
            </div>
        </div>
        <div class="home-btn"><button class="slot-btn" (click)="BackToHome()">Go to Homepage <i class="ti ti-arrow-narrow-right"></i></button></div>
    </div>
</div>