import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { RegistrationService } from 'src/app/_services/registration.service';
import { BroadCastService } from 'src/app/_shared/_services/broadcast.service';
import { LoaderService } from 'src/app/_shared/_services/loader.service';
import { RegistrationSuccessService } from 'src/app/_shared/_services/registeration-success.service';

@Component({
  selector: 'app-registrationsuccess',
  templateUrl: './registrationsuccess.component.html'
})
export class RegistrationsuccessComponent {

  PatientName:any;
  categoryName:string = "";

  constructor(
    private title: Title,
    private router: Router,
    private registrationService: RegistrationService,
    private broadCastService: BroadCastService,
    private loader:LoaderService,
    private page: RegistrationSuccessService

  ) {
    title.setTitle("VCareUrgentCare | Registration Success");
  }

  ngOnInit() {

    let patientName = localStorage.getItem("PatientName");
    if (patientName !== null) this.PatientName = patientName;

    let visitType = localStorage.getItem('CategoryName');
    if (visitType !== null) this.categoryName = visitType;

    if (!this.PatientName && !this.categoryName) {
      this.loader.setLoaderStatus(true);
      window.location.href = environment.HomePath;
    }

    if(this.categoryName){
      const splitArr = this.categoryName.split(' ');
      const capitalizedArr = splitArr.map((word: string) => word[0].toUpperCase() + word.substring(1));
      this.categoryName = capitalizedArr.join(' ');
    }

    this.loader.setLoaderStatus(false);
    this.page.setPageStatus(true);

    localStorage.clear();
  }

  BackToHome() {
    window.location.href = environment.HomePath;    //--Back to Home Page
  }

}
