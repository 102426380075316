<div class="max-670">
  <div class="content-header">
    <h2>Book an appointment</h2>
    <p>Select the type of service needed for the appointment.</p>
  </div>

  <div class="content-scroll">
    <div class="appointment-category">
      <ul>
        <li *ngFor="let appointment of appointmentreasonwithslot; index as i;">
          <a href="{{appointment.URL}}">
            <i class="ti ti-{{appointment.ImagePath}} position-icon left-icon"></i>
            <div class="category-slot">
              <h4>{{appointment.name}}<span>{{appointment.Slot}}</span></h4>
            </div>
            <p>{{appointment.Description}}</p>
            <i class="ti ti-arrow-narrow-right position-icon right-icon"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
