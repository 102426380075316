import { Component, Input, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { Router } from '@angular/router';
import { CommonHelper } from 'src/app/_shared/_helpers/CommonHelper';
import { AppointmentService } from 'src/app/_services/appointment.service';
import { AppointmentDetails, LocationDetails } from 'src/app/_models/visitreasonmodel';
import { environment } from 'src/environments/environment';
import { BroadCastService } from 'src/app/_shared/_services/broadcast.service';
import { LoaderService } from 'src/app/_shared/_services/loader.service';
import { PlatformLocation } from '@angular/common';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { AlertService } from 'src/app/_shardviews/_alert';
import { atcb_action } from "add-to-calendar-button";


@Component({
  selector: 'app-appointmentsuccess',
  templateUrl: './appointmentsuccess.component.html',  
})
export class AppointmentSuccessComponent {
  LocationDetails!: Array<LocationDetails>;
  AppointmentDetails:any;
  AppoitmentId: Number = 0;
  VisitDetails!: string;
  isPageLoaded: any;
  localItems: any;
  cancelFlag : boolean = true;
  @Input() PatientName: string = "";
  IsProviderNotMapped: boolean = false;
  startDate :any="";
  endDate :any="";
  RescheduleApp :boolean=false;
  constructor(
    private title: Title,
    private commonHelper: CommonHelper,
    private router: Router,
    private appointmentService: AppointmentService,
    private broadCastService: BroadCastService,
    private loader: LoaderService,
    private location: PlatformLocation,
    private alertService: AlertService,
    private http:HttpClient
  ) {
    title.setTitle("VCare Urgent Care | Booking Appointment");
    this.LocationDetails = new Array<LocationDetails>();
    this.AppointmentDetails = new AppointmentDetails();

    let _visitReason = localStorage.getItem("_visitReason");
    let visitReasonCategory = localStorage.getItem("visitReasonCategory");
    if (_visitReason && visitReasonCategory)
      this.VisitDetails = `${visitReasonCategory} - ${_visitReason}`;


  }

  ngOnInit() {
    let patientName = localStorage.getItem("PatientName");
    if (patientName !== null) this.PatientName = patientName;

    this.location.onPopState(() => {
      window.location.pathname = "appointment";
    });

    localStorage.removeItem("registrationDetails");
    this.AppoitmentId = Number(localStorage.getItem('AppointmentId'));
    this.IsProviderNotMapped = Boolean(localStorage.getItem('IsProviderNotMapped'));
    if(this.IsProviderNotMapped)
      this.RescheduleApp = false
    else
    {
      this.RescheduleApp = localStorage.getItem("RescheduledAppointment") != null? true: false;
      localStorage.removeItem("RescheduledAppointment");
    }
    this.localItems = JSON.stringify(localStorage);
    if (!this.AppoitmentId) {
      localStorage.clear();
      // this.router.navigate(['appointment']);
      window.location.href = environment.HomePath;
    }
    else {
      this.loader.setLoaderStatus(true);
      localStorage.removeItem('AppointmentId');
      this.getLocation();
    }
  }
  getLocation() {
    this.appointmentService.getClinicLocation().subscribe(data => {
      if (data) {
        this.LocationDetails = data;
        this.IsProviderNotMapped ? this.getAwaitingAppoitnmentDetails() : this.getAppoitnmentDetails();
      }
    });
  }
  getAppoitnmentDetails() {
    this.appointmentService.getAppointmentDetails(this.AppoitmentId).subscribe(data => {
      if (data) {
        let date = data?.Result?.AppointmentOn;
        date = new Date(date);
        this.AppointmentDetails = data?.Result;
        this.AppointmentDetails.ApponitmentOn= data?.Result?.AppointmentOn
        this.startDate= data?.Result?.AppointmentOn;
        this.endDate= data?.Result?.EndTime;
        let location = this.LocationDetails.find(x => x.LocationId === this.AppointmentDetails.LocationId);
        if (location)
          this.AppointmentDetails.Location = location;
      }
    });
    this.loader.setLoaderStatus(false);
  }
  BackToAppointment() {
    localStorage.clear();
    let _href = window.location.origin;
    //window.location.href = _href;
    window.location.href = environment.HomePath;    //--Back to Home Page
  }

  getAwaitingAppoitnmentDetails() {
    this.appointmentService.getAwaitingAppoitnmentDetails(this.AppoitmentId).subscribe(data => {
      if (data) {
        let date = data?.Result?.AppointmentOn;
        date = new Date(date);
        this.AppointmentDetails = data?.Result;
        this.AppointmentDetails.ApponitmentOn= data?.Result?.AppointmentOn;
        this.startDate= data?.Result?.AppointmentOn;
        this.endDate= data?.Result?.EndTime;
        let location = this.LocationDetails.find(x => x.LocationId === this.AppointmentDetails.LocationId);
        if (location)
          this.AppointmentDetails.Location = location;
      }
    });
    this.loader.setLoaderStatus(false);
  }
  cancelAppointment(appointmentId: any) {
    this.loader.setLoaderStatus(true);
    this.appointmentService.CancelScheduleAppointment(appointmentId).subscribe((response : any) => {
      if (response) {
        this.loader.setLoaderStatus(false);
        this.alertService.success(response.Message);
        this.cancelFlag = false
      }
    });
  }
  Reschedule(){
    var item = JSON.parse(this.localItems);
    let Category = item.visitReasonCategory
    let VisitReason = item._visitReason;
    localStorage.setItem('ExistingTime',this.AppointmentDetails.AppointmentOn)
    localStorage.setItem('RescheduledAppointment',"true")
    window.location.href = `/appointment-time-slots?category=${encodeURIComponent(Category ? Category : "")}&subcategory=${encodeURIComponent(VisitReason ? VisitReason : "")}&AptId=${this.AppoitmentId}`
  }
  Redirect(val : boolean)
  {
    localStorage.clear();
    if(val)
      window.location.href = window.origin;
    else
      window.location.href = environment.HomePath;
  }
  successFunction(value : any) {
    var lat = value.coords.latitude;
    var lng = value.coords.longitude;
    console.log(lat,lng);
    window.open(environment.GoogleMapPath +"origin="+lat+","+lng+"&destination=VCare+Urgent+%26+Primary+Care%2C+485+Georges+Rd%2C+South+Brunswick+Township%2C+NJ+08810%2C+United+States", '_blank');
  }
  getDirections() {
    navigator.geolocation
      .getCurrentPosition(
        this.successFunction
      );
  }

  addToCalendar(): void {

    var item = JSON.parse(this.localItems);
    let createdTime = new Date().toISOString();
    let startTime = new Date(this.startDate).toISOString().substring(11, 16);
    let startDate = new Date(this.startDate).toISOString().substring(0, 10);
    let endTime = new Date(this.endDate).toISOString().substring(11, 16);
    let summary = 'VCare Urgent Care Health Visit';
    let ClinicLocation = 'VCare Urgent & Primary Care, 485 Georges Rd, South Brunswick Township, NJ 08810, United States';
    let description = item.visitReasonCategory;

    const config : any = {
      name: summary,
      description: description,
      startDate: startDate,
      startTime: startTime,
      endTime: endTime,
      options: ["Apple","Google","iCal"],
      location : ClinicLocation
    };
    const button = document.getElementById('my-calendar-button');
    if (button) {
      // button.addEventListener("click", (e) => {
        atcb_action(config, button)
      // });
    }
  }
  
}
