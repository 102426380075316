import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './_shardviews/page-not-found/page-not-found.component';

const routes: Routes = [];
// const routes: Routes = [
//     { path: 'uicard', component: DashboardComponent },
//   ];

const routes2: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'appointment' },
  {
    //Leave as Empty
    path: '',
    data: { anonymous: true },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./_views/_patientappointment/patientappointment.module').then(
            (m) => m.PatientAppointmentModule
          ),
      },
    ],
  },
  {
    //Leave as Empty
    path: '',
    data: { anonymous: true },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./_views/_register/register.module').then(
            (m) => m.RegisterModule
          ),
      },
    ],
  },
  {
    //Leave as Empty
    path: '',
    data: { anonymous: true },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./_views/_unsubscribe/unsubscribe.module').then(
            (m) => m.UnsubscribeModule
          ),
      },
    ],
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes2)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
