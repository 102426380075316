<span class="masked-calendar">
  <datetime-mask [(ngModel)]="date"
                 [disabled]="disabled"
                 [format]="format"
                 [inputId]="inputId"
                 [maskStyle]="maskStyle" [tabindex]="tabindex">
  </datetime-mask>
  <p-calendar #calendar
              [(ngModel)]="date"
              [dateFormat]="dateFormat"
              [firstDayOfWeek]="firstDayOfWeek"
              [hourFormat]="hourFormat"
              [maxDate]="maxDate"
              [minDate]="minDate"
              [monthNavigator]="monthNavigator"
              [showIcon]="true"
              [showSeconds]="showSeconds"
              [showTime]="showTime"
              [yearNavigator]="yearNavigator"
              [yearRange]="yearRange">
  </p-calendar>
</span>
