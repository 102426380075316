import { Injectable } from "@angular/core";

@Injectable()
export class CommonHelper {

  AddPathInURL(pagePathName: string) {
    if (pagePathName) {
      var _href = window.location.href.split("#");
      _href = _href[0].split("?");
      var url = "";
      if (_href[0].indexOf(pagePathName) > -1) {
        url = _href[0].split("/" + pagePathName)[0];
      }
      else {
        url = _href[0];
      }
      url += "/" + pagePathName + (_href.length > 1 ? "?" + _href[1] : "");
      //$("iframe[cframe]").attr("src",url);
      window.history.pushState(null, "", url);
    }
  }
  AddPathBeforeInURL(pagePathName: string) {
    if (pagePathName && window.location.href.indexOf(pagePathName) === -1) {
      let currentPageUrl = window.location.toString();
      let isLocalPort = parseInt(window.location.port) != 80;

      var splitedPath = [];
      if (isLocalPort) {

        splitedPath = currentPageUrl.split(window.location.hostname + ":" + window.location.port);

      }
      else {
        splitedPath = currentPageUrl.split(window.location.hostname);
      }
      if (splitedPath.length > 1) {
        var url = pagePathName + splitedPath[1];

        // window.history.pushState(null, "", url);
      }
    }
  }
  GetHostURLAlone() {
    return window.location.origin;
  }
  RemovePathInURL() {
    var _href = window.location.href.split("?")[0];
    if (_href)
      window.history.pushState(null, "", _href);
  }
  FindDayName(dayNumber: number) {
    var weekdays = new Array(7);
    weekdays[0] = "Sunday";
    weekdays[1] = "Monday";
    weekdays[2] = "Tuesday";
    weekdays[3] = "Wednesday";
    weekdays[4] = "Thursday";
    weekdays[5] = "Friday";
    weekdays[6] = "Saturday";
    return weekdays[dayNumber];
  }
  FindMonthName(monthNumber: number) {
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    return monthNames[monthNumber];
  }
  AddDaysInDate(cdate: Date, days: number) {
    var date = cdate;
    date.setDate(date.getDate() + days);
    return date;
  }
  AddMiniutesInDate(mins: number) {
    var date = new Date();
    date.setMinutes(mins);
    return date;
  }

  AddZeroWithPadding(inputstring: string, padString: string, length: number = 2) {
    var str = inputstring;
    while (str.length < length)
      str = padString + str;
    return str;
  }

  GetDateAsString(date: Date) {
    return `${date.getFullYear()}-${this.AddZeroWithPadding((date.getMonth() + 1).toString(), "0")}-${this.AddZeroWithPadding(date.getDate().toString(), "0")}`
  }

  GetSwappedMonthAndDay(dateTimeValue: string) {
    let formatStrArray = dateTimeValue.toLowerCase().split(",");

    let formatDateArray = formatStrArray[0].split("/");
    let finalValue = `${formatDateArray[1]}/${formatDateArray[0]}/${formatDateArray[2]}, ${formatStrArray[1]}`;
    //   alert(finalValue);
    return finalValue;
  }

  GetDateAsStringWithFormat(date: Date, format: string = "m-d-y", splitchar: string = "-") {

    let formatStrArray = format.toLowerCase().split(splitchar);

    let finalValue = '';

    for (let i = 0; i < formatStrArray.length; i++) {

      switch (formatStrArray[i]) {

        case "m":

          if (finalValue)

            finalValue = `${finalValue}${splitchar}${this.AddZeroWithPadding((date.getMonth() + 1).toString(), "0")}`;

          else

            finalValue = this.AddZeroWithPadding((date.getMonth() + 1).toString(), "0");

          break;

        case "d":

          if (finalValue)

            finalValue = `${finalValue}${splitchar}${this.AddZeroWithPadding((date.getDate()).toString(), "0")}`;

          else

            finalValue = this.AddZeroWithPadding((date.getDate()).toString(), "0");

          break;

        case "y":

          if (finalValue)

            finalValue = `${finalValue}${splitchar}${date.getFullYear()}`;

          else

            finalValue = date.getFullYear().toString();

          break;

      }

    }

    return finalValue;

  }

  GetDateAsStringWithSpecificDivider(date: Date, divider: string = "-") {
    return `${date.getFullYear()}${divider}${this.AddZeroWithPadding((date.getMonth() + 1).toString(), "0")}${divider}${this.AddZeroWithPadding(date.getDate().toString(), "0")}`
  }

  IsMobile = function () {

    return window.outerWidth < 768 ? true : false;
  }

  convertDateTime(inputDateTime: string): string {
    const inputDate = new Date(inputDateTime);

    const year = inputDate.getFullYear();
    const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
    const day = inputDate.getDate().toString().padStart(2, '0');
    const hours = (inputDate.getHours()).toString().padStart(2, '0');
    const minutes = inputDate.getMinutes().toString().padStart(2, '0');

    const convertedDateTime = `${year}-${month}-${day}T${hours}:${minutes}Z`;
    return convertedDateTime;
  }

  GetDateAsStringWithSpecificDividerUSFormat(date: Date, divider: string = "-") {
    return `${this.AddZeroWithPadding((date.getMonth() + 1).toString(), "0")}${divider}${this.AddZeroWithPadding(date.getDate().toString(), "0")}${divider}${date.getFullYear()}`
  }

  isMobileDevice() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      console.log('Mobile');
      return true;
    } else {
      console.log('web')
      return false;
    }
  }

  UpdateUSPhoneNumberFormat(value: any) {
    
    if (!value)
      return ""; 

    let firstpart,secondpart,thirdpart,finalValue;
    let phonenumber = value;

    phonenumber = this.getNumberAlone(phonenumber);

    if (phonenumber.length > 10) {
      phonenumber = phonenumber.substring(0, 10);
    }

    if (phonenumber.length >= 3)
      firstpart = phonenumber.substr(0, 3);
    if (phonenumber.length >= 4) {
      let length = phonenumber.length > 6 ? 3 : phonenumber.length - 3;
      secondpart = phonenumber.substr(3, length);
    }
    if (phonenumber.length > 6)
      thirdpart = phonenumber.substr(6, phonenumber.length - 4);

    if (firstpart && !secondpart && !thirdpart)
      finalValue = `(${firstpart})`;

    //console.log(` Lenght ${phonenumber.length} <-> phonenumber ${phonenumber} 1) finalValue - ${finalValue}`);
    if (secondpart && !thirdpart)
      finalValue = `(${firstpart}) ${secondpart}`;

    //console.log(` Lenght ${phonenumber.length} <-> phonenumber ${phonenumber} 2) finalValue - ${finalValue}`);
    if (thirdpart)
      finalValue = `(${firstpart}) ${secondpart}-${thirdpart}`;

      return finalValue;
  }

  getNumberAlone(phonenumber: string) {
    phonenumber = phonenumber.replaceAll('(', '');
    phonenumber = phonenumber.replaceAll(')', '');
    phonenumber = phonenumber.replaceAll(' ', '');
    phonenumber = phonenumber.replaceAll('-', '');
    ////console.log(` After Removed phonenumber ${phonenumber}`)
    return phonenumber;
  } 
}
