import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Calendar } from 'primeng/calendar';
import { CommonHelper } from 'src/app/_shared/_helpers/CommonHelper';

/**
 * Komponenta pro výběr data s maskou vstupu
 */
@Component({
  selector: 'masked-calendar',
  templateUrl: './masked-calendar.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MaskedCalendarComponent),
      multi: true
    }
  ],
  styleUrls: [
    './masked-calendar.component.css'
  ]
})
export class MaskedCalendarComponent implements ControlValueAccessor {

  @Input() dateFormat: string = 'mm/dd/yy';
  @Input() firstDayOfWeek: number = 0;
  @Input() hourFormat: string = '24';
  @Input() disabled: boolean = false;
  @Input() inputId!: string;
  @Input() maxDate!: Date;
  @Input() minDate!: Date;
  @Input() monthNavigator!: boolean;
  @Input() name!: string;
  @Input() showSeconds: boolean = false;
  @Input() showTime!: boolean;
  @Input() yearNavigator!: boolean;
  @Input() yearRange!: string;

  constructor(private commonHelper: CommonHelper) { }
  /**
   * Setter formátovacího řetězce
   */
  @Input()
  public format!: string;

  /**
   * Příznak vstupu pouze pro čtení
   */
  @Input()
  public readonly!: boolean;

  /**
   * Styl pro InputMask
   */
  @Input()
  public maskStyle: any;

  @Input()
  public tabindex: any;

  /**
   * Datum komponenty
   */
  private __date: Date | null | undefined;

  /**
   * Callback pro vyvolání změny modelu
   */
  private __onModelChange: (args?: any[]) => void = () => { };

  /**
   * Setter kalendáře PrimeNG
   */
  @ViewChild('calendar')
  public set calendar(newValue: Calendar) {
    (newValue?.inputfieldViewChild.nativeElement as HTMLInputElement).setAttribute('type', 'hidden');
  }

  /**
   * Getter data komponenty
   */
  public get date(): Date | null | undefined {
    return this.__date;
  }

  /**
   * Setter data komponenty
   */
  public set date(newValue: Date | null | undefined) {
    // let HasVal = (document.getElementById("date-to") as HTMLInputElement)?.value;
    // if (newValue && !HasVal)
    let HasVal : any; 
    if (newValue)
      HasVal = this.commonHelper.GetDateAsStringWithSpecificDividerUSFormat(newValue, '/');
    if (HasVal) {
      let validDate = this.checkValidDate(HasVal);
      if (validDate) {
        this.writeValue(newValue);
        this.__onModelChange(newValue as any);
      }
      else {
        this.writeValue(null);
        this.__onModelChange(null as any);
      }
    }
  }

  /**
   * Nastaví stav DISABLED
   */
  public setDisabledState(val: boolean): void {
    this.disabled = val;
  }

  /**
   * Zaregistruje callback pro změnu modelu
   */
  public registerOnChange(fn: (args?: any[]) => void): void {
    this.__onModelChange = fn;
  }

  /**
   * Zaregistruje callback pro vyvolání sáhnutí na komponentu
   */
  public registerOnTouched(fn: (args?: any[]) => void): void {
  }

  /**
   * Nastaví hodnotu elementům
   */
  public writeValue(value: Date | null | undefined): void {
    this.__date = value;
  }

  public checkValidDate(value: any) {
    let dateArray = new Array<string>();
    let thirtyDaysMonth = [4, 6, 9, 11];
    let flag = false;
    if (value)
      dateArray = value.toString().split('/');
    if (dateArray && dateArray.length > 0) {
      for (var i = 0; i < dateArray.length; i++) {
        switch (i) {
          case 0://month
            flag = Number(dateArray[i]) > 0 && Number(dateArray[i]) <= 12;
            break;
          case 1://day
            if (Number(dateArray[0]) === 2) {
              if ((Number(dateArray[2]) % 4) === 0)
                flag = Number(dateArray[i]) > 0 && Number(dateArray[i]) <= 29;
              else
                flag = Number(dateArray[i]) > 0 && Number(dateArray[i]) <= 28;
            } else if (thirtyDaysMonth.filter(x => x === Number(dateArray[0])))
              flag = Number(dateArray[i]) > 0 && Number(dateArray[i]) <= 30;
            else
              flag = Number(dateArray[i]) > 0 && Number(dateArray[i]) <= 31;
            break;
          case 2://year
            flag = Number(dateArray[i]) >= this.minDate.getFullYear() && Number(dateArray[i]) <= this.maxDate.getFullYear();
            break;
          default:
            return flag;
        }
        if (!flag)
          return flag;
      }
    }
    return flag;
  }
}
