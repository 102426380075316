export class AppointmentModel {
  LocationId: number = 0;
  ProviderId: number = 0;
  VisitTypeId: number = 0;
  VisitReasonId: number = 0;
  Others!: string;
  AppointmentedOnDate: any;
  AppointmentedOnTime: any;
  UserId: number = 0
  SubReason: string = '';
}
