<!-- <a  browserBackAction class="back-btn">
  <i class="ti ti-arrow-narrow-left"></i>
</a> -->
<div class="max-670">
  <div class="content-header">
    <h2>Great! Finally, How did you initially hear about us?</h2>
  </div>
  <div class="content-scroll">
    <div class="appointment-category referral-box">
      <ul>
        <li *ngFor="let item of knownVcare?.knownVcare">
          <a (click)="onSelect(item.Mvalue)">
            <i class="ti {{item.ImagePath}} position-icon left-icon"></i>
            <div class="category-slot">
              <h4>{{item.caption}}</h4>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
