import { NgModule, isDevMode, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {
  CommonModule,
  HashLocationStrategy,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { ApplicationRouting } from './_views/_application/application-routing.module';
import { PatientAppointmentRouting } from './_views/_patientappointment/patientappointment-routing.module';
import { JwtInterceptor } from './_shared/_interceptors/jwt.interceptor';
import { EncryptDecryptHttpInterceptor } from './_shared/_interceptors/encryptdecrypt.interceptor';
import { ErrorInterceptor } from './_shared/_interceptors/error.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AlertModule } from './_shardviews/_alert/alert.module';
import { PageNotFoundComponent } from './_shardviews/page-not-found/page-not-found.component';
import { RegisterRouting } from './_views/_register/register-routing.module';
import { BroadCastService } from './_shared/_services/broadcast.service';
import { HeaderComponent } from './_shardviews/_header/header.component';
import { DirectivesModule } from './_shared/_directives/directives.module';
import { environment } from 'src/environments/environment';
import 'add-to-calendar-button';

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent, HeaderComponent],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EncryptDecryptHttpInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: 'googleTagManagerId', useValue: environment.GoogleTagManagerId },
    // BroadCastService
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PatientAppointmentRouting,
    RegisterRouting,
    AlertModule,
    HttpClientModule,
    BrowserAnimationsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
