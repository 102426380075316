import { ChangeDetectorRef, Component } from '@angular/core';
import { Location } from '@angular/common';
import { BroadCastService } from './_shared/_services/broadcast.service';
import { Subject } from 'rxjs';
import { LoaderService } from './_shared/_services/loader.service';
import { PageNotFoundService } from 'src/app/_shared/_services/page-not-found.service';
import { RegistrationSuccessService } from 'src/app/_shared/_services/registeration-success.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { NavigationEnd, Router } from '@angular/router';
import { RegistrationsuccessComponent } from './_views/_register/registrationsuccess/registrationsuccess.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {


  constructor(private loader: LoaderService,private ref: ChangeDetectorRef,private page : PageNotFoundService,
    private gtmService: GoogleTagManagerService,private router: Router,private success : RegistrationSuccessService) { }


  ngOnInit() { 
    this.router.events.forEach(item => {
      
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url
        };
        console.log(JSON.stringify(gtmTag));
        this.gtmService.pushTag(gtmTag);
      }
    });
  }

  getLoaderStatus() {
    return this.loader.getLoaderStatus();
  } 

  getPageStatus() {
    return this.page.getPageStatus();
  } 
  
  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  getSuccessPageStatus() {
    return this.success.getPageStatus();
  } 

}
