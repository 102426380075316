import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { AppointmentVisitReasonSlotModel, CovidBO } from '../../../_models/visitreasonmodel';
import { AppointmentService } from 'src/app/_services/appointment.service';
import { AlertService } from 'src/app/_shared/_services/alert-service';
import { EncryptDecryptService } from '../../../_shared/_services/security-service';
import { CommonHelper } from 'src/app/_shared/_helpers/CommonHelper';
import { ActivatedRoute, Router } from '@angular/router';
import { BroadCastService } from 'src/app/_shared/_services/broadcast.service';
import { LoaderService } from 'src/app/_shared/_services/loader.service';

@Component({
  selector: 'app-visitreason',
  templateUrl: './visitreason.component.html',
})
export class VisitReasonComponent {
  visitreasonservicesubwithslot!: Array<AppointmentVisitReasonSlotModel>;
  SearchReason!: string;
  public searchFilter: any = '';
  public CategoryName: any = '';
  public CategoryId: any = '';
  visitReasonCategory!: string;
  covidReasons: string = '';
  masterDDList: any;
  covidtime: string = "";
  @Input() isCovid!: boolean;
  @Input() isCovidWarning: boolean = false;
  otherValidation: boolean = false;
  charValidation: boolean = false;
  covidReasonList!: Array<CovidBO>;

  appointmentreasonwithslot!: Array<AppointmentVisitReasonSlotModel>;

  @ViewChild('closeModal') closeModal: any;

  constructor(
    private appointmentService: AppointmentService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private commonHelper: CommonHelper,
    private encryptionService: EncryptDecryptService,
    private title: Title,
    private meta: Meta,
    private broadCastService: BroadCastService,
    private loader: LoaderService
  ) {
    this.route.queryParamMap.subscribe((params: any) => {
      let _visitType = params.get('category');
      let _visitTypeWithOutDecode = _visitType;
      try {
        this.CategoryName = decodeURIComponent(_visitType);
      } catch {
        this.CategoryName = (this.CategoryName) ? this.CategoryName : _visitTypeWithOutDecode;
      }
      title.setTitle(`${this.CategoryName} Appointment - VCare South Brunswick, NJ`);
      this.meta.updateTag({name:'description',content:`VCare provides ${this.CategoryName} services to Adults & Kids in Brunswick, Dayton, NJ. Book an Appointment Instantly`});
    });
   
  }

  ngOnInit() {
    // //this.broadCastService.setMessage('PageLoad');
    this.appointmentreasonwithslot = [];
    this.loader.setLoaderStatus(true);
    this.visitreasonservicesubwithslot = [];
    this.CategoryName = '';
    this.CategoryId = '';
    this.isCovid = false;
    this.masterDDList = null;
    this.covidReasons = '';
    this.covidReasonList = [];
    this.getVisitReasonOnLoad();
  }
  pageLoad() {
    this.route.queryParamMap.subscribe((params: any) => {
      let _visitType = params.get('category');
      let _visitTypeWithOutDecode = _visitType;
      try {
        this.CategoryName = decodeURIComponent(_visitType);
      } catch {
        this.CategoryName = (this.CategoryName) ? this.CategoryName : _visitTypeWithOutDecode;
      }

      if (this.CategoryName) {
        this.visitReasonCategory = this.CategoryName;
        localStorage.setItem('visitReasonCategory', this.CategoryName);
        this.loadvisitreasonsbycategory(this.CategoryName);
      } else {
        this.router.navigate(['appointment']);
      }
    });
    if (this.closeModal) this.closeModal.nativeElement.click();
  }

  SetVisitReasonId(app: AppointmentVisitReasonSlotModel) {
    localStorage.removeItem("ActiveSlotDetail");
    localStorage.removeItem("ActiveDaySliderConfigs");
    localStorage.setItem('visitReasonCategoryId', app.id.toString());
    if ((document.getElementById('OtherReason') as HTMLInputElement) != null)
      (document.getElementById('OtherReason') as HTMLInputElement).value = "";
    localStorage.removeItem("OtherValue");
  }
  getVisitResons(categoryid: any, isCovid: boolean) {
    this.isCovid = isCovid;
    this.appointmentService
      .getVisitResons(categoryid, isCovid)
      .subscribe((data: any[]) => {
        if (data) {
          this.loader.setLoaderStatus(false);
          if (this.isCovid) {
            this.masterDDList = data;
            this.masterDDList.CovidTest.forEach((element: any) => {
              element.Slot = this.covidtime;
            });
          } else {
            data.forEach((key: any, val: any) => {
              if (key) {
                key.URL = `/appointment-time-slots?category=${encodeURIComponent(this.visitReasonCategory)}&subcategory=${encodeURIComponent(key.name)}`;
              }

              this.visitreasonservicesubwithslot.push(key);
            });
          }
        }
        this.loader.setLoaderStatus(false);
      });
  }

  getVisitReasonOnLoad() {
    this.appointmentService.getVisitCategory().subscribe((data: any[]) => {
      if (data) {
        data.forEach((key: any, val: any) => {
          key.URL = `/appointment-visit-reason?category=${key.name}`;
          this.appointmentreasonwithslot.push(key);
        });
        if(this.appointmentreasonwithslot?.filter(x => x.name == 'COVID Testing').toString() != ''){
        let covidStorage = this.appointmentreasonwithslot.filter(x => x.name == 'COVID Testing')[0].Slot;
        localStorage.setItem("covidtime", covidStorage);
        }
        localStorage.setItem("categoryitems", JSON.stringify(data));
        this.pageLoad();
        this.loader.setLoaderStatus(false);
      }
    });
  }

  loadvisitreasonsbycategory(param: any): void {
    let values = localStorage.getItem('categoryitems');
    let appointmentreasonwithslot!: Array<AppointmentVisitReasonSlotModel>;
    appointmentreasonwithslot = values ? JSON.parse(values) : null;
    if (appointmentreasonwithslot) {
      let itemFound = appointmentreasonwithslot.find((x) => x.name.trim() == param.trim());
      this.covidtime = itemFound?.name.toLocaleLowerCase().includes("covid testing") ? itemFound?.Slot : "";
      let categoryid = itemFound ? itemFound.id.toString() : '';
      this.CategoryName = itemFound?.name;
      this.CategoryId = itemFound?.id;
      if (categoryid) {
        localStorage.setItem('visitReasonSubCategoryId', categoryid);
        this.getVisitResons(
          categoryid,
          this.CategoryName.toLowerCase().includes('covid')
        );
      }
      else
        this.router.navigate(['appointment']);
    }
    else
      this.router.navigate(['appointment']);
  }
  getOtherReasons() {
    var _href = window.location.href.split('?')[0];

    if (this.isCovid) {
      var _href = this.commonHelper.GetHostURLAlone();
      if (!_href.includes('/time-slots?')) {
        localStorage.setItem("CovidResonList",JSON.stringify(this.covidReasonList));
        _href += `/appointment-time-slots?category=${this.visitReasonCategory}&subcategory=${this.covidReasons.substring(0, this.covidReasons.length - 1)}`;
        window.location.href = _href;
        this.loader.setLoaderStatus(false);
      }
    } else {
      var Otherlink = (
        document.getElementById('OtherReason') as HTMLInputElement
      ).value.replace(/\s*\"/g, '"').replace(/\"\s*/g, '"').replaceAll('&', ' and ');
      var regex = /^[A-Za-z0-9. \n&]+$/
      var isValid = regex.test(Otherlink);
      if (!isValid) {
        this.charValidation = true
        return
      } else {
        this.charValidation = false
      }
      if (Otherlink != null && Otherlink != "") {
        this.otherValidation = false;
        localStorage.setItem("OtherValue", (document.getElementById('OtherReason') as HTMLInputElement).value);
        this.appointmentService
          .getVisitResons(this.CategoryId)
          .subscribe((data) => {
            if (data) {
              this.loader.setLoaderStatus(false);
              if (this.closeModal) this.closeModal.nativeElement.click();
              var _href = this.commonHelper.GetHostURLAlone();
              if (!_href.includes('/time-slots?')) {
                _href += `/appointment-time-slots?category=${this.visitReasonCategory}&subcategory=${Otherlink}`;
              }
              window.location.href = _href;
            }
          });
      }
      else {
        this.otherValidation = true;
      }
    }

  }
  onFindSlots(fn: any) {
    this.isCovidWarning = this.covidReasons.length == 0;
    if (!this.isCovidWarning)
      this.getOtherReasons();
    this.loader.setLoaderStatus(false);
  }

  changed(event: Event, input: any) {
    const target = event.target as HTMLInputElement;
    if (target.checked) {
      let push = new CovidBO();
      push.id = input.id.toString();
      push.value = input.value;
      push.name = input.name;
      push.Description = null;
      push.ImagePath = null;
      this.covidReasonList.push(push);
      this.covidReasons += target.checked && target.nextElementSibling ? target.nextElementSibling.textContent?.replace(' ' + this.covidtime, '') + ',' : '';
    }
    else {
      let pop = new CovidBO();
      pop.id = input.id.toString();
      pop.value = input.value;
      pop.name = input.name;
      pop.Description = null;
      pop.ImagePath = null;
      let res = this.covidReasonList.indexOf(pop);
      this.covidReasonList.splice(res, 1);
      let reason = target.nextElementSibling && target.nextElementSibling.textContent != null ? target.nextElementSibling.textContent.replace(' ' + this.covidtime, '') + ',' : "";
      this.covidReasons = this.covidReasons.replace(reason, '');
    }
  }
  Bind()
  {
    if(this.SearchReason != null)
      (document.getElementById('OtherReason') as HTMLInputElement).value = this.SearchReason;
  }
}
