import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment.dev';
import * as Forge from 'node-forge';

@Injectable({
    providedIn: 'root'
})
export class EncryptDecryptService {
    private key = CryptoJS.enc.Utf8.parse(environment.encryptKey);
    private iv = CryptoJS.enc.Utf8.parse(environment.encryptIV);

    constructor() { }
    // Methods for the encrypt and decrypt Using AES
    encryptUsingAES256(text: any): any {
        var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), this.key, {
            keySize: 128 / 8,
            iv: this.iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.toString();
    }
    decryptUsingAES256(decString: any) {
        var decrypted = CryptoJS.AES.decrypt(decString, this.key, {
            keySize: 128 / 8,
            iv: this.iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return decrypted.toString(CryptoJS.enc.Utf8);
    }
    encryptWithPublicKey(valueToEncrypt: string): string {
      
        const rsa = Forge.pki.publicKeyFromPem(environment.applicationKey);      
        return window.btoa(rsa.encrypt(valueToEncrypt.toString()));
    }
    EncryptFieldData(data: any) {
        var key = this.key;
        var iv = this.iv;
        var encryptedData = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data), key, {
            keySize: 128 / 8, iv: iv,
            mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7
        });
        var encrypt = encryptedData.toString();

        // encrypt = encrypt.split("+").join("$amil$");
        // encrypt = encrypt.split("/").join("@amil@");
        return encrypt;
    }
    EncryptDataWithKey(data: any) {
        const rsa = Forge.pki.publicKeyFromPem(environment.applicationKey);      
        return window.btoa(rsa.encrypt(data));
    }
}