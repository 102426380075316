import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Title, Meta } from "@angular/platform-browser";
import { AppointmentService } from 'src/app/_services/appointment.service'; 
import { Availablity, CovidBO, DaySliderModel, GetAllAvailableSlots, GetAvailableSlots, GetProvider, ProviderModel, SlotModel, SlotModelDup, vistdetails } from 'src/app/_models/visitreasonmodel';
 
import { CommonHelper } from 'src/app/_shared/_helpers/CommonHelper';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/_shardviews/_alert/alert.service';
import { environment } from 'src/environments/environment';
import { ViewportScroller } from '@angular/common';
import { AppointmentModel } from 'src/app/_models/appointmentmodel'; 
import { LoaderService } from 'src/app/_shared/_services/loader.service';
import { SaveLogModel } from 'src/app/_models/registermodel';
import { RegistrationService } from 'src/app/_services/registration.service';

@Component({
  selector: 'app-timeslots',
  templateUrl: './timeslots.component.html'
})
export class TimeSlotComponent {

  @ViewChild('panel', { read: ElementRef })
  public panel!: ElementRef<any>;

  @ViewChild('closebutton') closebutton!: any;

  @Input() Appointment: AppointmentModel = new AppointmentModel();
  @Input() CurrentRecommendedProviderModel: ProviderModel = new ProviderModel();
  @Input() ActiveDaySliderConfigs: DaySliderModel = new DaySliderModel();
  @Input() NextAvailableSlotDetails: DaySliderModel = new DaySliderModel();
  @Input() ActiveSlotDetail: SlotModel = new SlotModel();

  ProviderCollection!: Array<ProviderModel>;
  DaySliderConfigs!: Array<DaySliderModel>;
  IsRecommendedProviderAvailable!: boolean;
  IsProviderChosen!: boolean;
  ChoosenSubCategory!: string;
  ChoosenCategory!: string;
  DisplaySelectedSlotInformation!: string;
  SliderDate!: Date;
  MinSliderDate!: Date;
  MaxSliderDate!: Date;
  SliderDateInString!: string;
  _SliderDate!: Date;
  _MinSliderDate!: Date;
  _MaxSliderDate!: Date;
  _InvalidNADates!: Array<Date>;
  Availability!: Availablity;
  Provider!: GetProvider;
  visitdetails!: Array<vistdetails>;
  color: any;
  isBindedOnPageLoad: boolean;
  currentIndex: number = 6;
  preButtonClassName = "disabled-arrow";
  nextButtonClassName = "enabled-arrow";
  webPageSize = 8;
  sliderpagecount = 7;
  AllSlots!: Array<SlotModelDup>;
  isPageLoadSlots: boolean = true;
  pageNumber = 0;
  firstIndex = 1;
  lastIndex = 7;
  indexFlag = 1;
  ProviderFlag = false;
  savelog!: any;
  LogId: any;
  AppointmentId: any;
  sliderGridData!: Array<any>;

  constructor(
    private appointmentService: AppointmentService,
    private route: ActivatedRoute,
    private router: Router,
    private commonHelper: CommonHelper,
    private title: Title,
    private meta: Meta,
    private loader: LoaderService,
    private registrationService: RegistrationService,
    private alertService: AlertService
  ) {
    // [visit-reason], [category-name] Appointment - VCare South Brunswick, NJ
    // title.setTitle("VCare Urgent Care | Booking Appointment - Time Slots");

    this.route.queryParamMap.subscribe((params: any) => {
      let _visitType = params.get('category');
      let _visitReason = params.get('subcategory');
      this.AppointmentId = params.get('AptId');
      

      let _visitTypeWithOutDecode = _visitType;
      let _visitReasonWithOutDecode = _visitReason;
      try {
        _visitType = decodeURIComponent(_visitType);
        _visitReason = decodeURIComponent(_visitReason);
      } catch {
        _visitType = (_visitType) ? _visitType : _visitTypeWithOutDecode;
        _visitReason = (_visitReason) ? _visitReason : _visitReasonWithOutDecode;
      }
      title.setTitle(`${_visitReason}, ${_visitType} Appointment - VCare South Brunswick, NJ`);
      this.meta.updateTag({ name: 'description', content: `VCare provides ${_visitType} services which includes ${_visitReason} to Adults & Kids in Brunswick, Dayton, NJ. Book an Appointment Instantly` });
    });
    this.ProviderCollection = new Array<ProviderModel>();
    this.CurrentRecommendedProviderModel = new ProviderModel();

    this.DaySliderConfigs = new Array<DaySliderModel>();
    this.ActiveDaySliderConfigs = new DaySliderModel();
    this.IsRecommendedProviderAvailable = false;
    this.IsProviderChosen = false;
    this.Availability = new Availablity();
    this.Provider = new GetProvider();
    this.visitdetails = new Array<vistdetails>();
    this.color = "yellow";
    this._InvalidNADates = new Array<Date>();
    this.isBindedOnPageLoad = false;
    this.ProviderFlag = false;
    this.sliderGridData = new Array<any>();
  }

  ngOnInit() {
    this.loader.setLoaderStatus(true);
    this.commonHelper.AddPathBeforeInURL("visit-reason");
    this.MinSliderDate = new Date();
    this.MaxSliderDate = this.commonHelper.AddDaysInDate(new Date(), 90);

    this.route.queryParamMap.subscribe((params: any) => {
      let _visitType = params.get('category');
      let _visitReason = params.get('subcategory');

      let _visitTypeWithOutDecode = _visitType;
      let _visitReasonWithOutDecode = _visitReason;
      try {
        _visitType = decodeURIComponent(_visitType);
        _visitReason = decodeURIComponent(_visitReason);
      } catch {
        _visitType = (_visitType) ? _visitType : _visitTypeWithOutDecode;
        _visitReason = (_visitReason) ? _visitReason : _visitReasonWithOutDecode;
      }

      if (_visitType && _visitReason) {
        this.bindVisitReasonByCaption(_visitType, _visitReason);
        this.ChoosenSubCategory = _visitReason;
        this.ChoosenCategory = _visitType;
        localStorage.setItem("_visitReason", _visitReason);
        localStorage.setItem("visitReasonCategory", _visitType);
      }
      else
        this.router.navigate(['appointment']);
    });
    this.formSliderData();
  }

  getProviders() {
    let request = new GetProvider();
    request.AppointmentTypeId = this.visitdetails[0]?.Id;
    request.LocationId = environment.VCareUrgentCareLocation;
    request.visitReason = this.visitdetails[1]?.Id;
    this.appointmentService.getProviders(request).subscribe(data => {
      if (data) {
        this.loader.setLoaderStatus(false);
        data.forEach((key: any, val: any) => {
          let _model = new ProviderModel();
          _model.Description = key.About;
          _model.Id = key.ProviderId;
          _model.ImagePath = "https://testapi.vcareurgentcare.com/api/admin/Admin/ProviderProfileImage/" + key.ProviderId;
          _model.IsRecommended = key.IsSuggested;
          _model.Name = key.ProviderName;
          _model.Slot = "";
          _model.IsMapped = key.IsMapped;
          this.ProviderCollection.push(_model);
        });

        let providerpreference = localStorage.getItem("CurrentRecommendedProviderModel");
        if (providerpreference) {
          let filterprovider = new ProviderModel();
          filterprovider = JSON.parse(providerpreference);
          filterprovider = this.ProviderCollection.filter(x => x.Id === filterprovider.Id)[0];
          if (filterprovider) {
            this.CurrentRecommendedProviderModel = filterprovider;
            this.IsProviderChosen = true;
          }
        }
        this.setAvailaibility();
      }
    });
  }

  setupDaySlider(adddays: number = 90) {
    let currentDate = new Date();
    this.DaySliderConfigs = [];
    if (adddays !== 2) {
      currentDate = new Date();
    }
    else {
      currentDate = this.DaySliderConfigs[this.DaySliderConfigs.length - 1].SliderDate;
      currentDate = this.commonHelper.AddDaysInDate(currentDate, 1);
      if (this.MaxSliderDate <= currentDate) {
        return false;
      }
    }
    var availableDayIndex = null;

    for (var i = 0; i <= adddays; i++) {

      let dayModel = new DaySliderModel();
      if (i !== 0) {
        currentDate = this.commonHelper.AddDaysInDate(currentDate, 1);
      }

      dayModel.DayName = this.commonHelper.FindDayName(currentDate.getDay());
      dayModel.Value = currentDate.getDate().toString();
      dayModel.SliderDateInString = this.commonHelper.GetDateAsStringWithSpecificDivider(currentDate, "/");
      var filteredObj = this.Availability.NextAvailableSlots.filter((item, index, self) => { return index === self.findIndex(obj => obj.AppOn === dayModel.SliderDateInString) });
      dayModel.SlotValue = filteredObj.length > 0 ? filteredObj[0].AppTime : 'NA';
      dayModel.CSSClass = dayModel.SlotValue === 'NA' ? "unavailable-slot" : "";
      if (availableDayIndex == null && filteredObj.length > 0) {
        availableDayIndex = i;
      }
      dayModel.SliderDate = currentDate;
      dayModel.MonthName = this.GetMonthName(currentDate.getMonth());

      this.DaySliderConfigs.push(dayModel);
    }
    let nextslots = this.DaySliderConfigs.filter(x => x.SlotValue !== 'NA');
    if (nextslots && nextslots.length > 1)
      this.NextAvailableSlotDetails = nextslots[1];
    else
      this.NextAvailableSlotDetails = new DaySliderModel();
    this.SliderDateInString = `${this.ActiveDaySliderConfigs.SliderDateInString}`;

    this._SliderDate = new Date(this.SliderDateInString);
    if (this._SliderDate) {
      this._MaxSliderDate = this.commonHelper.AddDaysInDate(new Date(), 90);
      this._MinSliderDate = new Date();
    }
    let naSlots = this.DaySliderConfigs.filter(x => x.SlotValue === 'NA');
    for (let it = 0; it < naSlots.length; it++) {
      let dt = new Date(naSlots[it].SliderDateInString);
      this._InvalidNADates.push(dt);
    }
    let ActiveDaySliderConfigsJson = localStorage.getItem("ActiveDaySliderConfigs");
    let ActiveDaySliderConfigsIndex = 0;
    if (ActiveDaySliderConfigsJson) {
      let ActiveDaySliderConfigs = JSON.parse(ActiveDaySliderConfigsJson);
      if (this.ProviderFlag)
        ActiveDaySliderConfigs.SliderDateInString = nextslots[0];
      this.ActiveSlotDetail.SlotValue = this.ActiveDaySliderConfigs.SlotValue;
      if (ActiveDaySliderConfigs)
        ActiveDaySliderConfigsIndex = this.DaySliderConfigs.findIndex(x => x.SliderDateInString === ActiveDaySliderConfigs.SliderDateInString && x.SlotValue === ActiveDaySliderConfigs.SlotValue);
    }
    ActiveDaySliderConfigsIndex > 0 ? this.GetSlotDetails(ActiveDaySliderConfigsIndex) : this.GetSlotDetails(availableDayIndex);
    return true;
  }

  setAvailaibility() {
    this.Availability = new Availablity();
    let currentDate = new Date();

    let request = new GetAvailableSlots();
    request.categoryId = this.visitdetails[0]?.Id;
    request.LocationId = environment.VCareUrgentCareLocation.toString();
    request.visitreasonId = this.visitdetails[1]?.Id;
    request.providerId = this.CurrentRecommendedProviderModel?.Id;
    request.Count = 90;
    request.startdate = this.commonHelper.GetDateAsString(currentDate);
    this.appointmentService.getAvailablity(request).subscribe(data => {
      if (data) {
        this.Availability = data;
        this.setupDaySlider();
      }
    });
  }

  GetMonthName(monthNumber: number) {
    return this.commonHelper.FindMonthName(monthNumber);
  }

  GetSlotDetails(dayIndex: any, event?: Event) {
    this.currentIndex = dayIndex = dayIndex != null ? dayIndex : 0;
    this.indexFlag = this.currentIndex;
    for (var i = 0; i < this.DaySliderConfigs.length; i++) {
      if (this.DaySliderConfigs[i].IsItemActive) {
        this.DaySliderConfigs[i].IsItemActive = false;
        this.DaySliderConfigs[i].CSSClass = this.DaySliderConfigs[i].SlotValue === 'NA' ? "unavailable-slot" : "";
      }
    }
    let nextslots = this.DaySliderConfigs.find((element, index) => index > dayIndex && element.SlotValue !== 'NA');
    if (nextslots)
      this.NextAvailableSlotDetails = nextslots;
    if (this.DaySliderConfigs[dayIndex]) {
      this.ActiveDaySliderConfigs = this.DaySliderConfigs[dayIndex];
      localStorage.setItem("ActiveDaySliderConfigs", JSON.stringify(this.ActiveDaySliderConfigs));
    }
    this.SliderDateInString = this.ActiveDaySliderConfigs.SliderDateInString;
    this._SliderDate = new Date(this.SliderDateInString);
    if (this._SliderDate) {
      this._MaxSliderDate = this.commonHelper.AddDaysInDate(new Date(), 90);
      this._MinSliderDate = new Date();
    }
    this.ActiveDaySliderConfigs.CSSClass = this.DaySliderConfigs[dayIndex].CSSClass = "active-slot";
    this.ActiveDaySliderConfigs.IsItemActive = this.DaySliderConfigs[dayIndex].IsItemActive = true;
    let appointment = localStorage.getItem("AppointmentDetails");
    if (appointment) {
      this.Appointment = JSON.parse(appointment);

      let activeDaySliderConfigs = localStorage.getItem("ActiveDaySliderConfigs");
      if (activeDaySliderConfigs)
        this.ActiveDaySliderConfigs = JSON.parse(activeDaySliderConfigs);

      let activeSlotDetails = localStorage.getItem("ActiveSlotDetail");
      if (activeSlotDetails)
        this.ActiveSlotDetail = JSON.parse(activeSlotDetails);
      const target = event && event.target as HTMLInputElement;
      if (target) { this.ProviderFlag = true }
      if (this.ProviderFlag)
        this.ActiveSlotDetail.SlotValue = this.ActiveDaySliderConfigs.SlotValue;
      this.Appointment.AppointmentedOnTime = this.ActiveSlotDetail.SlotValue;
      localStorage.setItem("ActiveSlotDetail", JSON.stringify(this.ActiveSlotDetail));
    }

    let request = new GetAvailableSlots();
    this.Appointment.VisitTypeId = request.categoryId = this.visitdetails[0]?.Id;
    request.LocationId = environment.VCareUrgentCareLocation.toString();
    this.Appointment.VisitReasonId = request.visitreasonId = this.visitdetails[1]?.Id;
    this.Appointment.ProviderId = request.providerId = this.CurrentRecommendedProviderModel?.Id;
    request.startdate = this.SliderDateInString;
    this.Appointment.AppointmentedOnDate = this.SliderDateInString;
    this.Appointment.LocationId = environment.VCareUrgentCareLocation
    this.loader.setLoaderStatus(true);
    this.isPageLoadSlots = true;
    this.appointmentService.getAllAvailableSlots(request).subscribe(data => {
      if (data) {
        this.loader.setLoaderStatus(false);
        this.isPageLoadSlots = false;
        this.ActiveDaySliderConfigs.Slots = [];
        this.ActiveDaySliderConfigs.MorningSlots = [];
        this.ActiveDaySliderConfigs.EveningSlots = [];
        this.ActiveDaySliderConfigs.AfternoonSlots = [];
        data?.forEach((key: SlotModelDup, val: any) => {
          let _model = new SlotModel();
          _model.SlotValue = key.SlotValue;
          _model.Id = key.ProviderId;
          _model.Session = key.Session;
          switch (_model.Session) {
            case 'Morning':
              this.ActiveDaySliderConfigs.MorningSlots.push(_model);
              break;
            case 'Afternoon':
              this.ActiveDaySliderConfigs.AfternoonSlots.push(_model);
              break;
            case 'Evening':
              this.ActiveDaySliderConfigs.EveningSlots.push(_model);
              break;
            default: break;
          }
          this.ActiveDaySliderConfigs.Slots.push(_model);

        });
        if (this.ActiveDaySliderConfigs.Slots.length > 0) {
          if (this.ProviderFlag) {
            this.ActiveSlotDetail = this.ActiveDaySliderConfigs.Slots[0];
            this.ActiveDaySliderConfigs.Slots[0].CSSClass = "active-slot";
          }
          else
            if (event) {
              this.ActiveSlotDetail = this.ActiveDaySliderConfigs.Slots[0];
              this.ActiveDaySliderConfigs.Slots[0].CSSClass = "active-slot";
            }
            else if (this.ActiveSlotDetail.Id > 0 && this.ActiveDaySliderConfigs.Slots.filter(x => x.SlotValue === this.ActiveSlotDetail.SlotValue).length > 0)
              this.ActiveDaySliderConfigs.Slots.filter(x => x.SlotValue === this.ActiveSlotDetail.SlotValue)[0].CSSClass = "active-slot";
            else {
              this.ActiveSlotDetail = this.ActiveDaySliderConfigs.Slots[0];
              this.ActiveDaySliderConfigs.Slots[0].CSSClass = "active-slot";
            }
        }
      }
      this.Appointment.AppointmentedOnDate = this.ActiveDaySliderConfigs.SliderDateInString;
      this.Appointment.ProviderId = this.ActiveSlotDetail.Id;
    });
    let isMobile = this.commonHelper.isMobileDevice();
    if (!isMobile)
      this.positionSliderNew(this.indexFlag);
    else {
      setTimeout(() => {
        var ele = `slotday${this.currentIndex}`;
        if (window.document.getElementById(ele)) {
          var element = window.document.getElementById(ele);
          this.FocusMe(element!);
        }
      }, 10);
    }
  }

  changeDate(event: any) {
    // this.loader.setLoaderStatus(true);
    let indexofItem = this.DaySliderConfigs.findIndex(x => x.SliderDateInString === this.commonHelper.GetDateAsStringWithSpecificDivider(event, "/"));
    if (indexofItem > -1) {
      this.ProviderFlag = true;
      this.GetSlotDetails(indexofItem);
      this.currentIndex = indexofItem;
      this.indexFlag = Math.round(indexofItem % 7) !== 0 ? (parseInt((indexofItem / 7).toString()) + 1) * 7 : (parseInt((indexofItem / 7).toString())) * 7;
      this.indexFlag = this.indexFlag - 7;
    }
    // this.loader.setLoaderStatus(false);
  }

  UpdateSlotInformation(ActiveDaySliderConfigs: DaySliderModel, slotBO: SlotModel, index: number) {
    this.loader.setLoaderStatus(true);
    this.ActiveDaySliderConfigs.Slots.filter(X => X.CSSClass === "active-slot")[0].CSSClass = "";
    this.ActiveSlotDetail = slotBO;
    let slotIndex = this.ActiveDaySliderConfigs.Slots.findIndex(x => x === slotBO);
    if (slotIndex)
      this.ActiveDaySliderConfigs.Slots[slotIndex].CSSClass = "active-slot";
    this.loader.setLoaderStatus(false);
    let ele = "slotday";
    this.currentIndex = this.DaySliderConfigs.findIndex(x => x === ActiveDaySliderConfigs)
    ele += this.currentIndex.toString();
    if (window.document.getElementById(ele)) {
      var element = window.document.getElementById(ele);
      this.FocusMe(element!);
    }
    this.BookAppointment();
  }

  toString(data: any) {
    return JSON.stringify(data);
  }

  BookAppointment() {
    let selectedslot = this.ActiveDaySliderConfigs.Slots.filter(x => x.CSSClass === "active-slot")[0];
    if (selectedslot)
      this.Appointment.ProviderId = selectedslot.Id;
    let IsProviderNotMapped = false;
    if (localStorage.getItem('IsProviderNotMapped') != null)
      localStorage.removeItem('IsProviderNotMapped');
    if (this.Appointment.ProviderId) {
      let item = this.ProviderCollection.filter(x => x.Id === this.Appointment.ProviderId)
      if (item && item.length > 0 && !item[0].IsMapped) {
        localStorage.setItem("IsProviderNotMapped", 'true');
        IsProviderNotMapped = true;
      }
    }
    this.loader.setLoaderStatus(false);
    this.Appointment.AppointmentedOnTime = this.ActiveSlotDetail.SlotValue;
    localStorage.setItem("AppointmentDetails", JSON.stringify(this.Appointment));
    localStorage.setItem("CurrentRecommendedProviderModel", JSON.stringify(this.CurrentRecommendedProviderModel));
    localStorage.setItem("ActiveDaySliderConfigs", JSON.stringify(this.ActiveDaySliderConfigs));
    localStorage.setItem("ActiveSlotDetail", JSON.stringify(this.ActiveSlotDetail));
    if (localStorage.getItem('registrationToAppointment') != null && (localStorage.getItem('RescheduledAppointment') == null || this.AppointmentId == null)) {
      IsProviderNotMapped ? this.saveAppointmentDetails() : this.saveAppointment();
      return;
    }
    if (this.AppointmentId != null) {
      this.RescheduleAppointment();
      return;
    }
    this.loader.setLoaderStatus(false);
    setTimeout(() => { this.router.navigate(["registration"]); }, 350)
  }

  private convertToCustomFormat(dateString: string): string {
    const dateObject = new Date(dateString);
    const year = dateObject.getFullYear().toString().padStart(4, '0');
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObject.getDate().toString().padStart(2, '0');
    const hours = dateObject.getHours().toString().padStart(2, '0');
    const minutes = dateObject.getMinutes().toString().padStart(2, '0');
    const seconds = dateObject.getSeconds().toString().padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000`;
  }

  onSelectProvider(provider: ProviderModel) {
    this.CurrentRecommendedProviderModel = provider;
    this.IsProviderChosen = true;
    this.IsRecommendedProviderAvailable = true;
    this.setAvailaibility();
    this.closebutton.nativeElement.click();
    localStorage.setItem("CurrentRecommendedProviderModel", JSON.stringify(this.CurrentRecommendedProviderModel));
    this.ProviderFlag = true;
  }

  ClearProvider() {
    localStorage.removeItem("CurrentRecommendedProviderModel");
    this.CurrentRecommendedProviderModel = new ProviderModel();
    this.IsProviderChosen = false;
    this.IsRecommendedProviderAvailable = false;
    this.ProviderFlag = true;
    this.setAvailaibility();
  }

  bindVisitReasonByCaption(visitType: string, visitReason: string) {
    let visitTypeText = visitType;
    let visitReasonText = visitReason;
    visitType = encodeURIComponent(visitType);
    visitReason = encodeURIComponent(visitReason);
    this.appointmentService.getVisitDetails(visitType, visitReason).subscribe(data => {
      if (data) {
        this.visitdetails = data;
        this.getProviders();
        if (visitTypeText === 'COVID Testing') {
          let CovidVisitReasons = localStorage.getItem("CovidResonList");
          if (!CovidVisitReasons) {
            this.appointmentService.getVisitResonsForCovid().subscribe(data => {
              if (data) {
                let covidArray: Array<any>;
                let CovidResonList: Array<CovidBO>;
                covidArray = [];
                CovidResonList = [];
                let res: any;
                res = data;
                let filter = visitReasonText.replaceAll('Test', '').split(',');

                if (filter && filter.length > 0) {
                  for (var i = 0; i < filter.length; i++) {
                    let val = res?.CovidTest.filter((a: { value: string; }) => a.value === filter[i].trim())[0];
                    if (val) {
                      let CovidReason = new CovidBO();
                      CovidReason.id = val.id.toString();
                      CovidReason.value = val.value;
                      CovidReason.name = val.name;
                      CovidReason.Description = null;
                      CovidReason.ImagePath = null;
                      CovidResonList.push(CovidReason);
                    }
                  }
                }

                if (CovidResonList && CovidResonList.length > 0)
                  localStorage.setItem("CovidResonList", JSON.stringify(CovidResonList));
              }
            });
          }
        }
      }
    });
  }

  MoveNextAvailableSlot(NextAvailable: DaySliderModel) {
    let slotIndex = this.DaySliderConfigs.findIndex(x => x === NextAvailable);
    if (slotIndex) {
      this.loader.setLoaderStatus(true);
      this.ProviderFlag = true;
      this.GetSlotDetails(slotIndex);
    }

    let nextslots = this.DaySliderConfigs.filter(x => x.SlotValue !== 'NA');
    if (nextslots && nextslots.length > 1) {
      let nSlot = nextslots.findIndex(x => x === NextAvailable);
      if (nSlot > -1 && nextslots.length >= nSlot + 1)
        this.NextAvailableSlotDetails = nextslots[nSlot + 1];
    }

  }

  GetDate(type: number) {
    return type === 0 ? new Date() : this.commonHelper.AddDaysInDate(new Date(), 90);
  }

  FocusMe(element: HTMLElement) {
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "end", inline: 'nearest' });
    }
  }
  checkDateForHoliday(date: any) {
    var calendarDate = new Date(date.year, date.month, date.day);
    calendarDate.setHours(0, 0, 0, 0);
    return this.isInArray(calendarDate);
  }

  isInArray(value: Date) {
    return !!this._InvalidNADates.find(item => {
      return item.getTime() == value.getTime()
    });
  }

  addSliderClass() {
    let minCount = 0;
    let maxCount = 90;
    if (this.currentIndex === minCount || this.currentIndex < 0) {
      this.currentIndex = minCount;
      this.preButtonClassName = "disabled-arrow";
      this.nextButtonClassName = "enable-arrow";
    }
    else if (this.currentIndex === maxCount) {
      this.preButtonClassName = "enable-arrow";
      this.nextButtonClassName = "disabled-arrow";
    }
    else {
      this.preButtonClassName = "enable-arrow";
      this.nextButtonClassName = "enable-arrow";
    }
  }

  saveAppointment() {
    var data = null;
    let RegistrationDetails = localStorage.getItem('registrationDetails');
    if (RegistrationDetails)
      data = JSON.parse(RegistrationDetails);
    this.loader.setLoaderStatus(true);
    let PatientId = localStorage.getItem("PatientId");
    if (PatientId)
      this.Appointment.UserId = Number(PatientId);
    this.Appointment.Others = localStorage.getItem("OtherValue") as string;

    let isCovid = localStorage.getItem("visitReasonCategory");
    if (isCovid && isCovid.trim() === 'COVID Testing') {
      let covidReasonList = localStorage.getItem("CovidResonList");
      if (covidReasonList)
        this.Appointment.SubReason = covidReasonList;
    }

    this.appointmentService
      .saveAppointment(this.Appointment)
      .subscribe((data) => {
        if (data != null && data?.Result?.ID > 0) {
          var _appointmentOn = this.Appointment.AppointmentedOnDate + ' ' + this.Appointment.AppointmentedOnTime;
          this.savelog = new SaveLogModel();
          this.savelog.AppointmentId = data?.Result?.ID;
          this.savelog.AppointmentOn = this.commonHelper.convertDateTime(_appointmentOn);
          this.savelog.FirstName = data.FirstName;
          this.savelog.LastName = data.LastName;
          this.savelog.DOB = data.DOB;
          this.savelog.UserId = this.Appointment.UserId;
          this.savelog.Type = 2;
          this.savelog.AppointmentId = data?.Result?.ID;
          let LogId = localStorage.getItem('LogId');
          this.savelog.Id = LogId != null ? Number(LogId) : 0;
          this.RegistrationSaveLog(this.savelog);
          localStorage.setItem('AppointmentId', data?.Result?.ID.toString());
          this.router.navigate(['/appointment-success']);
          this.loader.setLoaderStatus(false);
        }
        else {
          this.loader.setLoaderStatus(false);
          this.alertService.warn('Failed to schedule Appointment.');
        }
      });
    this.loader.setLoaderStatus(false);
  }
  RegistrationSaveLog(log: SaveLogModel) {
    try {
      this.registrationService.saveRegLog(log).subscribe((data) => {
        if (data) { if (log.Type != 2) { this.LogId = data } }
      });
    }
    catch (e) {
      console.log('Error in appointment log')
    }
  }

  //new slider logic
  formSliderData() {
    let sliderdata = [];

    for (var i = 0; i < 90; i++) {


      sliderdata.push(i);
    }
    this.sliderGridData = [];
    for (var i = 0; i <= 12; i++) {
      let start = 0;
      let end = 0;
      if (i === 0) {
        start = 0;
        end = i + 6;
      }
      else {
        start = (i * 7);
        end = start + 6;
      }
      let data = [];
      let sliderDateData = [];
      for (var j = start; j <= end; j++) {
        if (sliderdata[j] >= 0) {
          data.push(sliderdata[j]);
          let date = new Date();
          date.setDate(date.getDate() + j);
          sliderDateData.push({ "index": j, "Date": date.getDate() });
        }
      }
      this.sliderGridData.push({ "PageNumber": i, "StartIndex": start, "EndIndex": end, "Sliderdata": data, "sliderDateData": sliderDateData });
    }
  }

  positionSliderNew(Id: number) {
    let begin = 0;
    let conclude = -1;
    let index = -1;
    let isfound = false;
    do {
      index++;
      conclude = conclude + 7;
      if (begin <= Id && conclude >= Id) {
        isfound = true;
      }
    }
    while (!isfound)

    let obj = this.sliderGridData.filter(x => x.PageNumber === index);
    this.designUpdate(obj);
    console.log(`Id ${Id} index ${index} value ${JSON.stringify(obj)}`);
  }

  moveFutureDays(type: Number) {
    let obj: any;
    switch (type) {
      case 1:
        if (this.pageNumber !== 12)
          this.pageNumber += 1;
        obj = this.sliderGridData.filter(x => x.PageNumber === this.pageNumber);
        break;
      case 0:
        if (this.pageNumber !== 0)
          this.pageNumber -= 1;
        obj = this.sliderGridData.filter(x => x.PageNumber === this.pageNumber);
        break;
      default:
        obj = this.sliderGridData.filter(x => x.PageNumber === this.pageNumber);
        break;
    }
    if (obj && obj.length > 0) {
      obj = obj[0];
      this.pageNumber = obj.PageNumber;
      if (this.pageNumber === 0) {
        this.preButtonClassName = "disabled-arrow";
        this.nextButtonClassName = "enable-arrow";
      }
      else if (this.pageNumber === 12) {
        this.preButtonClassName = "enable-arrow";
        this.nextButtonClassName = "disabled-arrow";
      }
      else {
        this.preButtonClassName = "enable-arrow";
        this.nextButtonClassName = "enable-arrow";
      }
      setTimeout(() => {
        var ele = `slotday${(type === -1 || type === 1) ? obj.Sliderdata[obj.Sliderdata.length - 1].toString() : obj.Sliderdata[0].toString()}`;
        if (window.document.getElementById(ele)) {
          var element = window.document.getElementById(ele);
          this.FocusMe(element!);
        }
      }, 10);
    }
  }

  designUpdate(data: any) {
    let oldPageNumber = this.pageNumber;
    let obj: any;
    if (data && data.length > 0)
      obj = data[0];
    if (obj) {
      this.pageNumber = obj.PageNumber;
      let type = oldPageNumber == this.pageNumber ? -1 : (oldPageNumber > this.pageNumber ? 0 : 1);
      if (obj) {
        this.pageNumber = obj.PageNumber;
        if (this.pageNumber === 0) {
          this.preButtonClassName = "disabled-arrow";
          this.nextButtonClassName = "enable-arrow";
        }
        else if (this.pageNumber === 12) {
          this.preButtonClassName = "enable-arrow";
          this.nextButtonClassName = "disabled-arrow";
        }
        else {
          this.preButtonClassName = "enable-arrow";
          this.nextButtonClassName = "enable-arrow";
        }
        setTimeout(() => {
          var ele = `slotday${(type === -1 || type === 1) ? obj.Sliderdata[obj.Sliderdata.length - 1].toString() : obj.Sliderdata[0].toString()}`;
          if (window.document.getElementById(ele)) {
            var element = window.document.getElementById(ele);
            this.FocusMe(element!);
          }
        }, 10);
      }
    }
  }
  //new slider logic
  saveAppointmentDetails() {
    var data = null;
    let RegistrationDetails = localStorage.getItem('registrationDetails');
    if (RegistrationDetails)
      data = JSON.parse(RegistrationDetails);
    this.loader.setLoaderStatus(true);
    let PatientId = localStorage.getItem("PatientId");
    if (PatientId)
      this.Appointment.UserId = Number(PatientId);
    this.Appointment.Others = localStorage.getItem("OtherValue") as string;
    let isCovid = localStorage.getItem("visitReasonCategory");
    if (isCovid && isCovid.trim() === 'COVID Testing') {
      let covidReasonList = localStorage.getItem("CovidResonList");
      if (covidReasonList)
        this.Appointment.SubReason = covidReasonList;
    }
    this.appointmentService
      .saveAppointmentDetails(this.Appointment)
      .subscribe((data) => {
        if (data != null && data?.Result?.ID > 0) {
          var appointmentId = data?.Result?.ID;
          localStorage.setItem('AppointmentId', appointmentId.toString());
          this.router.navigate(['/appointment-success']);
          this.loader.setLoaderStatus(false);
        }
        else {
          this.loader.setLoaderStatus(false);
          this.alertService.warn('Failed to schedule Appointment.');
        }
      });
  }
RescheduleAppointment() {
  var aptdetails = JSON.stringify(localStorage.getItem("AppointmentDetails"))
  let apt = JSON.parse(aptdetails);
  apt = JSON.parse(apt);
  var appointment :any = {}
  appointment.AppointmentId = this.AppointmentId;
  appointment.ExistingAppointmentTime = localStorage.getItem("ExistingTime");
  appointment.AppointmentedOnDate = apt.AppointmentedOnDate;
  appointment.AppointmentedOnTime = apt.AppointmentedOnTime;
  appointment.ProviderId = apt.ProviderId;
  appointment.ProviderId = apt.ProviderId;
  this.loader.setLoaderStatus(true);
  this.appointmentService.RescheduleAppointment(appointment).subscribe((response) => {
    if (response != null) {
      this.loader.setLoaderStatus(false);
      if(response.Result == null)
      {
        this.alertService.error(response.Message);
      }
      setTimeout(()=>
      {
        localStorage.setItem('AppointmentId',response.Result.AppointmentId)
        this.router.navigate(['/appointment-success']);
      },1000);
    }
  });
}
}

