import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { RegistrationComponent } from "./registration/registration.component";
import { ReferralComponent } from "./referral/referral.component";
import { RegistrationsuccessComponent } from "./registrationsuccess/registrationsuccess.component";

 

// Shared Lib Imports


// Compontents Imports
// import { LoginComponent } from "./login/login.component";

const routes: Routes = [
  {
    path: 'registration',
    component: RegistrationComponent 
  },  
  {
    path: 'referral',
    component: ReferralComponent 
  },
  {
    path:'registrationsuccess',
    component: RegistrationsuccessComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RegisterRouting {

}

