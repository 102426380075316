import { List } from "lodash";

export class AppointmentVisitReasonSlotModel {
    ImagePath!: string;
    Description!: string;
    URL!: string;
    id!: number;
    name!: string;
    Slot!: string;
 }
 export class ProviderModel {
    ImagePath!: string;
    Description!: string;
    Id!: number;
    Name!: string;
    Slot!: string;
    IsRecommended!:boolean;
    IsMapped:boolean = true;
 }
 export class DaySliderModel {
    Value!: string;
    DayName!: string;
    MonthName!:string;
    SlotValue!: string;
    Slots!: Array<SlotModel>;
    MorningSlots!: Array<SlotModel>;
    EveningSlots!: Array<SlotModel>;
    AfternoonSlots!: Array<SlotModel>;
    CSSClass!:string;
    SliderDate!:Date;
    SliderDateInString!:string;
    IsItemActive!:boolean;
    constructor() {
       this.Slots = new Array<SlotModel>();
       this.MorningSlots = new Array<SlotModel>();
       this.EveningSlots = new Array<SlotModel>();
       this.AfternoonSlots = new Array<SlotModel>();
       this.CSSClass="";
       this.IsItemActive=false;
    }
 }
 export class SlotModel {
    Id!: number;
    SlotValue!: string;
    CSSClass!:string;
    Session!:string;
    constructor() {
        this.CSSClass="";
        this.Session="";
    }
 }

 export class GetProvider {
    AppointmentTypeId: number = 0;
    LocationId: number = 0;
    visitReason: number = 0;
    providerId: number = 0;
}

export class GetAvailableSlots {
  categoryId: number = 0;
  LocationId: string = '';
  visitreasonId: number = 0;
  providerId: number = 0;
  startdate: string = "";
  endddate: string = "";
  UserId: number = 0;
  Count: number = 0;
  IsSlots: boolean = false;
}

export class AvailableSlots {
  Morning!: Array<string>;
  Afternoon!: Array<string>;
  Evening!: Array<string>;
}

export class Availablity {
  AvailableSlots! : AvailableSlots;
  NextAvailableSlots! : Array<NextAvailableSlots>
}

export class NextAvailableSlots {
  AppOn : string = "";
  AppTime : string = "";
}

export class vistdetails {
  Id : number = 0;
  Name : string = "";
}

export class LocationDetails {
  name: string = "";
  id: number = 0;
  text: string = "";
  LocationId: number = 0;
  LocationName: string = "";
  LocationAddress: string = "";
  Phone: string = "";
  ImagePath: string = "";
}

export class AppointmentDetails{
  VisitReasonId: Number =0;
  VisitReasonName: string ="";
  CategoryId: Number =0;
  CategoryName: string ="";
  ApponitmentOn!: Date;
  LocationId: Number =0;
  Location: LocationDetails = new LocationDetails();
}
export class SlotModelDup {
  ProviderId!: number;
  SlotValue: string = "";
  CSSClass:string = "";
  Session:string = "";
}

export class GetAllAvailableSlots {
  categoryId: number = 0;
  LocationId: string = '';
  visitreasonId: number = 0;
  providerId: number = 0;
  startdate: string = "";
}

export class CovidBO{
  id: string = '';
  value: string = '';
  name: string = '';
  ImagePath!: null;
  Description!: null;
}