import { Component, OnInit } from '@angular/core';
import { PageNotFoundService } from 'src/app/_shared/_services/page-not-found.service';
declare var jsCustom: any;
@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  constructor(private page:PageNotFoundService) { }

  ngOnInit(): void {
    this.page.setPageStatus(true);

    // jsCustom.ShowHideCancelCtrl(false);
    // jsCustom.ShowHideBackCtrl(false);
    // jsCustom.ShowHideRegBackCtrl(false);
  }

  BackToAppointment() {
    let _href = window.location.origin;
    window.location.href = _href;
  }

}
