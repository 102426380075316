import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AppointmentDetails, AppointmentVisitReasonSlotModel, AvailableSlots, Availablity, GetAllAvailableSlots, LocationDetails, ProviderModel, SlotModelDup, vistdetails } from '../_models/visitreasonmodel';


import { concat } from 'lodash';
import { AppointmentModel } from '../_models/appointmentmodel';

@Injectable({ providedIn: 'root' })
export class AppointmentService {

  private visitcategory_api_url: string = `/appointment/Appointment/GetVisitSlotList/`;
  private visitreason_api_url: string = `/appointment/Appointment/GetVisitReasonSlotList/`;
  private provider_api_url: string = `/appointment/Appointment/ProviderDetails`;
  private availableslots_api_url: string = `/appointment/Appointment/AvailableSlots`;
  private availability_api_url: string = `/appointment/Appointment/get-availability-for-days`;
  private covidreason_api_url: string = `/appointment/Appointment/MasterDDList/CovidTest`;
  private visitdetails_api_url: string = `/appointment/Appointment/GetVisitDetails/`;
  private saveAppoinemtn_api_url: string = `/appointment/Appointment/SavePatientAppointment/`;
  private clinicList_api_url: string = `/appointment/Appointment/LocationList/`;
  private appointmentdetails_api_url: string = `/appointment/Appointment/GetAppointmentDetails/`;
  private allavailableslots_api_url: string = `/appointment/Appointment/get-available-slots`;
  private saveAppointmentDetails_api_url: string = `/appointment/Appointment/save-appointment-details/`;
  private getAwaitingAppointmentDetails_api_url: string = `/appointment/Appointment/get-awaiting-appointment-details/`;
  private reschedule_appointment_api_url: string = `/appointment/Appointment/ReschedulePatientAppointment`;
  private cancelschedule_appointment_api_url: string = `/appointment/Appointment/CancelPatientAppointment?&appointmentID=`;

  private visitreasons!: Observable<AppointmentVisitReasonSlotModel[]>;

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private http: HttpClient) {

  }

  //#region Appointment
  getVisitCategory() {
    return this.http.get<Array<AppointmentVisitReasonSlotModel>>(`${environment.apiUrl}${this.visitcategory_api_url}${environment.VCareUrgentCareLocation}`, this.httpOptions);
  }
  getVisitResons(categoryid: any, isCovid?: boolean) {
    var URL = isCovid ? `${environment.apiUrl}${this.covidreason_api_url}` : `${environment.apiUrl}${this.visitreason_api_url}${environment.VCareUrgentCareLocation}/${categoryid}`;
    return this.http.get<Array<AppointmentVisitReasonSlotModel>>(URL, this.httpOptions);
  }
  getVisitResonsForCovid() {
    return this.http.get<Array<AppointmentVisitReasonSlotModel>>(`${environment.apiUrl}${this.covidreason_api_url}`, this.httpOptions);
  }
  getProviders(provider: any) {
    return this.http.post<Array<ProviderModel>>(`${environment.apiUrl}${this.provider_api_url}`, provider);
  }
  getAvailableSlots(slot: any) {
    return this.http.post<AvailableSlots>(`${environment.apiUrl}${this.availableslots_api_url}`, slot);
  }
  getAvailablity(slot: any) {
    return this.http.post<Availablity>(`${environment.apiUrl}${this.availability_api_url}`, slot);
  }
  getVisitDetails(visitType: string, visitReason: string) {
    return this.http.get<Array<vistdetails>>(`${environment.apiUrl}${this.visitdetails_api_url}${visitType}/${visitReason}`, this.httpOptions);
  }
  saveAppointment(request: AppointmentModel) {
    return this.http.post<any>(`${environment.apiUrl}${this.saveAppoinemtn_api_url}`, request, this.httpOptions);
  }
  getClinicLocation() {
    return this.http.get<Array<LocationDetails>>(`${environment.apiUrl}${this.clinicList_api_url}`, this.httpOptions);
  }
  getAppointmentDetails(AppointmentId : Number) {
    return this.http.get<any>(`${environment.apiUrl}${this.appointmentdetails_api_url}${AppointmentId}`, this.httpOptions);
  }
  getAllAvailableSlots(slot: GetAllAvailableSlots) {
    return this.http.post<Array<SlotModelDup>>(`${environment.apiUrl}${this.allavailableslots_api_url}`, slot);
  }
  saveAppointmentDetails(request: AppointmentModel) {
    return this.http.post<any>(`${environment.apiUrl}${this.saveAppointmentDetails_api_url}`, request, this.httpOptions);
  }
  getAwaitingAppoitnmentDetails(AppointmentId : Number) {
    return this.http.get<any>(`${environment.apiUrl}${this.getAwaitingAppointmentDetails_api_url}${AppointmentId}`, this.httpOptions);
  }
  RescheduleAppointment(request: any) {
    return this.http.post<any>(`${environment.apiUrl}${this.reschedule_appointment_api_url}`,request, this.httpOptions);
  }
  CancelScheduleAppointment(request: any) {
    return this.http.post<any>(`${environment.apiUrl}${this.cancelschedule_appointment_api_url}${request}`, this.httpOptions);
  }
  //#endregion Appointment
}
