<a *ngIf="step1IsActive && !isRegistration && !registrationToAppointment" browserBackAction class="back-btn">
  <i class="ti ti-arrow-narrow-left"></i>
</a>
<a *ngIf="step2IsActive" (click)="toggleForm()" class="back-btn">
  <i class="ti ti-arrow-narrow-left"></i>
</a>
<!-- <span *ngIf="step1IsActive" (click)="showAddBarCodePage()" class="scanner-icon">
  <img src="../assets/images/scanner.svg" alt="Scan Your Driver's License">
</span> -->

<div *ngIf="showAddBarCode" class="modal fade" id="barcodeModal" data-bs-backdrop="static" data-bs-keyboard="false"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered document-popup">
    <div class="modal-content" id="UploadDocForm">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Scan Barcode</h5>
        <button id="closeBtn" type="button" class="btn-close" (click)="closeBarCode()"></button>
      </div>
      <div class="modal-body form-box">
        <div>
          <input id="hdnOrgId" type="hidden" value="{{barCodeValue}}" />
          <div id="UIElement" style="height:60vh; background:none;">
            <img id="btn-open-image" alt="open-image" hidden
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAADU0lEQVR4Xu2bSchOURjHfx8bw8IQygZZkUQkFBYkhVggKxk3LMxLkZKyMEUW5iFK2JCFDCXKFBFiZ1grdoYy9Nd96/Z2733P+d57zj33e89ZvX33OcP/d54zPM93bxcdXro6XD8RQPQAewJ9gE3ATEC/XZSfwCXgoovG023aLoERwC1gjOuBJe1vB/a77MsWwF1gtssBZbTtFIINgEnAc8/iG905g2ADYAlw1QOAR8D0jH62AQfK7t8GwFLgStkDyGhvLrAA2OwDQqgA7gAHfUAIGYAcIA/C1uRZ2w4ZOgDnEOoAwCmEugBwBqFOAIogbAEOdWdDqBuA0iHUEUCpEOoKoDQIIQJYDFw3XM9Z94S/wHjgrUkbIQK4DSwEfpkIyLks6dp+zaR+2QCeAReAL0nOYAMwxGQgTTYfgBcW9eYDfVP2y0wDtzIB7AL2AH9SAxmWBFCzLMSUYeodwA1gUc7IhyfrcVAZygzb8A5A8fvjgsEpjtdlxVfxDmBosu7zBK4HjvlSD3gHoGPnTYHA3cDOngzgCLAxR2A/4DUwuicD0M6vTfBmk8jewHFgjUfx6sr7ElCnuoGdAM6l7gE7gCmexVcGoAKduV1W4gERQEAEogdUEQsE5ADVnAIRQEAE4h4Qyh7wCdA7Be+AUcAMYEILT3kFPAQ+AmOBOcBIS++q3AN0K1S+TjfB76nBKwGjLNE+oH+GKP33eXlyq2w8VqZHiRaF06YJnEoBKC5YB5wpmDV5wn2gV4aNgioFV81F8cRJQwiVAZD4tcBZA5ddkcQNWbOaB2F1AiELXLrLSgBIvGZJwZBpEQTN9oCMCtOAJxl/XwWcyvGehrl3ABKv2Tlvqjxlp03xHjC4qW7RKzErgdMFELwD0MwXrflWXCYmp0UawlTgaUFFAReErOIdgDK+31qpbPF8HLA38YSjwOUW9gOBrz0JgC2/CCB6QFwCcQ8IZhN8Cfy23cXatFfKXcdnEMdgm1pKr+79HlC6gjYbjABcJER8vS7f5uT/r+7kFZkqP5iwhTLZ9BUb0wxLYwBVfDJjK16RpdJoRsUWgO+PpoxEpIzeA/OAz6YVbQGoXR+fzZmOv2H3A3gAHAb027h0B4Bx43UwjADqMEsux9jxHvAPwx/cQdZmcYgAAAAASUVORK5CYII=" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div #messageDiv *ngIf="step1IsActive" class="scan-content">
  <span class="info-icon" (click)="showtheinformationmodel(true)" #messageInfoIcon><i
      class="ti ti-z1_info-circle"></i></span>
  <div class="msg-body">
    <div class="msg-body-inner">
      <i class="ti ti-bulb-filled"></i>
      <span>Save time and speed up your registration with a quick scan of your driver's license.</span>
      <button class="msg-close btn-close" (click)="showtheinformationmodel(false)"><i class="ti ti-x"></i></button>
    </div>
  </div>
</div> -->
<div class="max-670">
  <div class="booking-box" *ngIf="VisitDetails">
    <div class="booking-box-inner d-flex align-items-center">
      <div class="location-img">
        <img src="assets/images/new-brunswik.jpg" alt="Vcare Urgent Primary Care">
      </div>
      <div class="location-details">
        <h5>{{VisitDetails}}</h5>
        <span>{{SlotDateDetails | date:'dd MMM yyyy'}} {{SlotTimeDetails}}</span>
      </div>
    </div>
  </div>

  <div class="content formstep1" *ngIf="step1IsActive">

      <!-- <div>
            <masked-calendar [(ngModel)]="date" 
                             (onSelect)="datechanged1($event)" (ngModelChange)="datechanged1($event)"
                             [ngModelOptions]="{standalone: true}" [ngClass]="{'is-invalid': submittedForm1 && RegisterStep1Form['DateOfBirth'].errors}"
                             [minDate]="DOBMinDate" [maxDate]="DOBMaxDate"
                             [maskStyle]="{width: '15rem'}"
                             [monthNavigator]="false"
                             [showSeconds]="false"
                             [showTime]="false"
                             [yearNavigator]="false"
                             format="MM/dd/yyyy"
                             inputId="date-to"
                             name="date"
                             yearRange="1900:2100">
            </masked-calendar>
          </div>
      
          <div>
            <pre>{{date|json}}</pre>
            <pre>{{date|json}}</pre>
          </div> -->

    <div class="content-header">
      <h2 [style.display]="isRegistration || registrationToAppointment ? 'none' : 'block'">Please provide the necessary details to confirm your booking.</h2>
      <h2 class="registeration-heading" [style.display]="isRegistration || registrationToAppointment ? 'block' : 'none'">Please provide the necessary details to confirm your registration.</h2>
    </div>
    <form [formGroup]="registerStep1Form" (ngSubmit)="onSubmitForm1()">
      <div class="content-scroll">
        <div class="appointment-fields">
          <div class="form-floating mb-3 position-relative">
            <input
              tabindex="1"
              type="text"
              formControlName="FirstName"              
              class="form-control"
              placeholder="First Name"
              maxlength="35"
              [ngClass]="{
                'is-invalid':
                  submittedForm1 && RegisterStep1Form['FirstName'].errors
              }"
            />
            <label>First Name <span class="required">*</span></label>
            <div *ngIf="submittedForm1 && RegisterStep1Form['FirstName'].errors" class="invalid-feedback">
              <div *ngIf="RegisterStep1Form['FirstName'].errors!['required']">
                First name is required.
              </div>
              <div *ngIf="RegisterStep1Form['FirstName'].errors!['minlength']">
                Please provide valid first name.
              </div>
              <div *ngIf="RegisterStep1Form['FirstName'].errors!['pattern']">
                Invalid characters not allowed.
              </div>
            </div>
          </div>
          <div class="form-floating mb-3 position-relative">
            <input
              tabindex="2"
              type="text"
              formControlName="LastName"              
              class="form-control"
              placeholder="Last Name"
              maxlength="35"
              [ngClass]="{
                'is-invalid':
                  submittedForm1 && RegisterStep1Form['LastName'].errors
              }"
            />
            <label>Last Name <span class="required">*</span></label>
            <div *ngIf="submittedForm1 && RegisterStep1Form['LastName'].errors" class="invalid-feedback">
              <div *ngIf="RegisterStep1Form['LastName'].errors!['required']">
                Last name is required.
              </div>
              <div *ngIf="RegisterStep1Form['LastName'].errors!['minlength']">
                Please provide valid last name.
              </div>
              <div *ngIf="RegisterStep1Form['LastName'].errors!['pattern']">
                Invalid characters not allowed.
              </div>
            </div>
          </div>
          <!-- <div class="form-floating mb-3 position-relative">


            <p-calendar [tabindex]="3" dateMask datePattern="MM/dd/yyyy" (onSelect)="datechanged($event)" (ngModelChange)="datechanged($event)" (onBlur)="ondateupdate($event)" [readonlyInput]="false"
                        [(ngModel)]="calendarDate" [ngModelOptions]="{standalone: true}" [ngClass]="{'is-invalid': submittedForm1 && RegisterStep1Form['DateOfBirth'].errors}"
                        [minDate]="DOBMinDate" [maxDate]="DOBMaxDate"></p-calendar>

            <!-- <p-inputMask #patientDoB2 [autoClear]="true" [unmask]="true" mask="99/99/9999"
    [ngModelOptions]="{standalone: true}" [ngModel]="GetMyDateFormat(calendarDate)" tabindex="3"
    (ngModelChange)="datechanged($event)" (onBlur)="ondateupdate($event)" slotChar="mm/dd/yyyy"></p-inputMask> -->
            <!-- <button class="position-icon right-icon calendar-icon"><i class="ti ti-calendar-event"></i></button> -->
            <!-- <label>Date of Birth <span class="required">*</span></label>

            <div *ngIf="submittedForm1 && RegisterStep1Form['DateOfBirth'].errors" class="invalid-feedback">
              <div *ngIf="RegisterStep1Form['DateOfBirth'].errors!['required']">
                Date Of Birth is required
              </div>
              <div *ngIf="RegisterStep1Form['DateOfBirth'].errors!['pattern']">
                Invalid Date.
              </div>
            </div>
          </div> -->
          <div class="form-floating mb-3 position-relative dob-field">
              <masked-calendar [(ngModel)]="calendarDate" autocomplete="new-password" [tabindex]="3" 
                               (onSelect)="DateValidation($event)" (ngModelChange)="DateValidation($event)"
                               [ngModelOptions]="{standalone: true}" [ngClass]="{'is-invalid': submittedForm1 && RegisterStep1Form['DateOfBirth'].errors}"
                               [minDate]="DOBMinDate" [maxDate]="DOBMaxDate" 
                               [monthNavigator]="false"
                               [showSeconds]="false"
                               [showTime]="false"
                               [yearNavigator]="true"
                               format="MM/dd/yyyy"
                               inputId="date-to"
                               name="date"
                               yearRange="{{DOBMinDate.getFullYear()}}:{{DOBMaxDate.getFullYear()}}">
              </masked-calendar> 
            <div *ngIf="isDOB" class="required-field" id="dobValid">
              Date of birth is required.
            </div>
            <div *ngIf="isFutureDate" class="required-field" id="isFuture">
              Date of birth must not be future date.
            </div>
            <div *ngIf="isMinDate" class="required-field" id="isMinimum">
              Please enter valid date.
            </div>
          </div>
          <div class="form-floating mb-3 position-relative">
            <input tabindex="4" autocomplete="new-password" type="tel" maxlength="14" formControlName="ContactNumber" vCarePhoneMask
              class="form-control" placeholder="Mobile Number" minlength="10" (blur)="CheckPhoneFormat()"
              [ngClass]="{'is-invalid': submittedForm1 && RegisterStep1Form['ContactNumber'].errors}" />
            <!-- <input type="text" class="form-control" formControlName="ContactNumber" placeholder="Mobile Number" [ngClass]="{'is-invalid': submitted && RegisterStep1Form['ContactNumber'].errors}"> -->
            <label>Mobile Number <span class="required">*</span></label>
            <!-- {{tostringvalue(RegisterStep1Form['ContactNumber'].errors)}} -->
            <div *ngIf="submittedForm1 && RegisterStep1Form['ContactNumber'].errors" class="invalid-feedback">

              <div *ngIf="RegisterStep1Form['ContactNumber'].errors!['required']">
                Mobile number is required.
              </div>
              <div *ngIf="RegisterStep1Form['ContactNumber'].errors!['pattern']">
                Invalid format of Mobile Number (000) 000-0000.
              </div>
              <div *ngIf="RegisterStep1Form['ContactNumber'].errors!['maxlength']">
                Must meets maximum length.
              </div>
              <div *ngIf="RegisterStep1Form['ContactNumber'].errors!['minlength']">
                Please enter valid phone number.
              </div>
            </div>
          </div>
          <div class="text-box mb-3 position-relative">
            <label>Select your gender <span class="required">*</span></label>
            <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
              <input tabindex="5" type="radio" class="btn-check" value="1" name="Gender" id="btnradio1" formControlName="Gender"
                [ngClass]="{'is-invalid': submittedForm1 && RegisterStep1Form['Gender'].errors}">
              <label class="btn btn-outline-primary" for="btnradio1">Male</label>

              <input tabindex="6" type="radio" class="btn-check" value="2" name="Gender" id="btnradio2" formControlName="Gender"
                [ngClass]="{'is-invalid': submittedForm1 && RegisterStep1Form['Gender'].errors}">
              <!-- <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off"> -->
              <label class="btn btn-outline-primary" for="btnradio2">Female</label>

              <input tabindex="7" type="radio" class="btn-check" value="3" name="Gender" id="btnradio3" formControlName="Gender"
                [ngClass]="{'is-invalid': submittedForm1 && RegisterStep1Form['Gender'].errors}">
              <!-- <input type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off"> -->
              <label class="btn btn-outline-primary" for="btnradio3">Others</label>

            </div>
            <div *ngIf="submittedForm1 && RegisterStep1Form['Gender'].errors" class="btn-group invalid-feedback">
              <div *ngIf="RegisterStep1Form['Gender'].errors!['required']">
                Gender option is required
              </div>
            </div>
          </div>
        </div>
        <div class="sticky-btn">
          <div class="small-btn">
            <button id="form1Submit" tabindex="8" class="slot-btn">Continue <i class="ti ti-arrow-narrow-right"></i></button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="content formstep2" *ngIf="step2IsActive">
    <div class="content-header">
      <h2 class="registeration-heading">Hi {{registerModel.FirstName}}, you're almost there...</h2>
      <p [style.display]="isRegistration ? 'none' : 'block'">Complete the following steps to confirm your booking.</p>
      <p [style.display]="isRegistration ? 'block' : 'none'">Complete the following steps to confirm your registration.</p>
    </div>
    <form [formGroup]="registerStep2Form" (ngSubmit)="onSubmitForm2()">
      <div class="content-scroll">
        <div class="appointment-fields">
          <div class="form-floating mb-3 position-relative">
            <input type="text" [(ngModel)] = "EmailId" formControlName="EmailId" spellcheck="false" autocapitalize="none" class="form-control" placeholder="Email Details"
              [ngClass]="{'is-invalid': submittedForm2 && RegisterStep2Form['EmailId'].errors}" />
            <label>Email Details <span class="required">*</span></label>
            <div *ngIf="submittedForm2 && RegisterStep2Form['EmailId'].errors" class="invalid-feedback">
              <div *ngIf="RegisterStep2Form['EmailId'].errors!['required']">
                Email Id is required
              </div>
              <div *ngIf="RegisterStep2Form['EmailId'].errors!['pattern']">
                Invalid Email Id
              </div>
            </div>
          </div>
          <div class="form-floating mb-3 position-relative">
            <input type="tel" [(ngModel)]="PostalCode" maxlength="5" minlength="4" formControlName="PostalCode" class="form-control"
              placeholder="Zip Code" vCareNumericMask vCareNumericMaxLength="5"
              [ngClass]="{'is-invalid': submittedForm2 && RegisterStep2Form['PostalCode'].errors}">
            <label>Zip Code <span class="required">*</span></label>
            <div *ngIf="submittedForm2 && RegisterStep2Form['PostalCode'].errors" class="invalid-feedback">
              <div *ngIf="RegisterStep2Form['PostalCode'].errors!['required']">
                Zip Code is required
              </div>
              <div *ngIf="RegisterStep2Form['PostalCode'].errors!['minlength']">
                Zip Code should be 5 digits
              </div>
              <div *ngIf="RegisterStep2Form['PostalCode'].errors!['pattern']">
                Zip code should contain only numbers
              </div>
            </div>
          </div>

        

        </div>
        <div class="sticky-btn">
          <div class="small-btn">
            <button class="slot-btn">Continue <i class="ti ti-arrow-narrow-right"></i></button>
          </div>
        </div>
      </div>
    </form>

  
  </div>
</div>
