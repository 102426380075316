export class RegisterModel {
    FirstName!: string;
    LastName!: string;
    DOB!: string;
    ContactNumber!: string;
    Gender!: string;
    EmailId!: string;
    PostalCode!: string;
    UserId!:number;
 }

 export class PatientRegisterModel {
    FirstName!: string;
    LastName!: string;
    DOB!: string;
    MobileNo!: string;
    Gender!: number;
    Email!: string;
    PostalCode!: string;
    isUpdate: boolean = false;
    isScannerUsed: boolean = false;
    IsServiceRegistration : boolean = false;
    CategoryName : string = "";
    IsExistingUser :boolean = false;
    PatientUserId! : Number;
 }

export class KnownVareModel {
  Mvalue: number = 0;
  Value: number = 0;
  text: string = "";
  caption: string = "";
  ImagePath: string = "";
}

export class KnownVareResponse{
  knownVcare! : Array<KnownVareModel>
}

export class PatientRefferal{
  knownVcareId : Number = 0;
  PatientId : Number = 0;
}

export class SaveLogModel {
  Id?: number;
  UserId?: number;
  FirstName: string = "";
  LastName: string = "";
  DOB?: any;
  Email: string = "";
  MobileNo: string="";
  Type?: number;
  AppointmentId?: number;
  AppointmentOn?: Date;
  RegDOB?: Date;
}

