import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AuthenticationService } from '../../_services/auth.service';
import { EncryptDecryptService } from '../_services/security-service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    [x: string]: any;
    constructor(
        private authenticationService: AuthenticationService,
        private encryptionService: EncryptDecryptService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
 
        // add authorization header with jwt token if available
        let currentUser = this.authenticationService.currentUserValue;
        if (currentUser && this.authenticationService.currentUserAuthToken) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.authenticationService.currentUserAuthToken}`
                }
            });
        }
        return next.handle(request).pipe(tap(() => { },
            (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status !== 401) {
                        return;
                    }
                    this['router'].navigate(['login']);
                }
            }));

        // return next.handle(request);
    }
}