import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Subject, first } from 'rxjs';
import { BroadCastService } from 'src/app/_shared/_services/broadcast.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent {

  title = '| Home';
  isLoggedIn = true;
  isBackEnabledOnRegister = false;
  isBackEnabled: boolean = false;
  isScannerNeeded: boolean = false;
  baseURL = 'appointment';
  registerURL = 'registration';
  HomePath: string = "";
  successURL = 'appointment-success';


  constructor(private location: Location, private broadCastService: BroadCastService) {
    this.isBackEnabled = true;
    this.isScannerNeeded = false;
  }


  ngOnInit() {
    this.HomePath = environment.HomePath;
    //this.doUpdateControls();

    // this.broadCastService.getMessage
    //     //.pipe(first())
    //     .subscribe((msg) => {
    //       if(msg==="MoveToPrev")
    //       {
    //         this.location.back()
    //       }
    //       if(msg==="PageLoad")
    //       {
    //         this.doUpdateControls();
    //       }
    //     }
    // );

  }

  // back(): void {
  //   this.location.back()
  // }

  // invokeChildComp(): void {
  //   this.broadCastService.setMessage('ShowForm1');
  // }

  // doUpdateControls(){
  //   alert();
  //   this.isBackEnabled = !(window.location.href.split('/')[3] == this.baseURL || window.location.href.split('/')[3] == '' || window.location.href.split('/')[3] == this.successURL);

  //   this.isBackEnabledOnRegister = ((window.location.href.split('/')[3] == this.registerURL));

  // }
}
